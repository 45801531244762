@import "../../static/sass/config";

.tbxContentModal{
   font-family: $fontClient_Regular;
   &.show {
      transition: .5s .3s ease-out;
      left: 0;
      visibility: visible;
      opacity: 1;
   }
   
   .hide {
      visibility: hidden;
   }
   .tbxNotificationModal-message{
      max-width: 600px;
   }
}

.tbxContentModalProfile {
   position: fixed;
   z-index: 500;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   color: #fff;
   box-shadow: 0 1.5rem 4rem rgba($color-dark, .15);
   transition: all .3s;

   @include responsive(tab_port) {
      height: 100% !important;
      overflow-y: overlay !important;
      display: grid;
      justify-items: center;
      grid-template-rows: min-content;
   }

   @include responsive(phone) {
      height: 100% !important;
      overflow-y: overlay !important;
      display: grid;
      justify-items: center;
      grid-template-rows: min-content;
   }

   .tbxNotificationModal-firstButton,.tbxNotificationModal-secondButton{
      transition: transform ease-in-out 250ms;
      &:hover{
         transform: scale(1.05);
      }
   }

}


