$fontClient_Regular: "fontClient_Regular";
$fontClient_Bold: "fontClient_Bold";
$fontClient_Medium: "fontClient_Medium";
$fontClient_Italic: "fontClient_Italic";
$fontClient_Light: "fontClient_Light";

@font-face {
  font-display: swap;
  font-family: $fontClient_Regular;
  font-weight: normal;
  src: local($fontClient_Regular),
    url("../../fonts/AMX/AMX-Regular.woff2") format("woff2"),
    url("../../fonts/AMX/AMX-Regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: $fontClient_Bold;
  font-weight: bold;
  src: local($fontClient_Bold),
    url("../../fonts/AMX/AMX-Regular.woff2") format("woff2"),
    url("../../fonts/AMX/AMX-Regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: $fontClient_Medium;
  font-weight: bold;
  src: local($fontClient_Medium),
    url("../../fonts/AMX/AMX-Regular.woff2") format("woff2"),
    url("../../fonts/AMX/AMX-Regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: $fontClient_Italic;
  font-style: italic;
  src: local($fontClient_Italic),
    url("../../fonts/AMX/AMX-Regular.woff2") format("woff2"),
    url("../../fonts/AMX/AMX-Regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: $fontClient_Light;
  src: local($fontClient_Light),
    url("../../fonts/AMX/AMX-Regular.woff2") format("woff2"),
    url("../../fonts/AMX/AMX-Regular.woff") format("woff");
}
