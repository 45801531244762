// Configuration
@import "./config";

// Main styles
@import "base/base";

// Experience Widgets Library Default Styles
@import "~@tbx/experience-widgets-lib/dist/index.cjs";

// Custom Player
@import "custom/skin";

// Client Specific Customizations for "tbx-experience-widgets-lib":
// Custom Client Styles
@import "custom/custom_styles";

// @import "custom/custom-bootstrap";
@import "custom/tbx-widgets";

// Custom Banner Hero
@import "custom/custom-bannerHero";

// Custom Carousel Poster
@import "custom/custom-carouselPoster";

// Custom Carousel Thumbs
@import "custom/custom-carouselThumb";

// Custom Content Overview
@import "custom/custom-contentOverview";

// Custom Content OverviewLive
@import "custom/custom-contentOverviewLive";

// Custom Content Related
@import "custom/custom-contentRelated";

// Custom Content Episodes
@import "custom/custom-contentEpisodes";

// Custom Content Card
@import "custom/custom-contentCard";

// Custom Content Grid
@import "custom/custom-contentGrid";

// Light Theme (always in the end of this file)
@import "themes/light";
