@import "../../static/sass/config";

.tbxDownloadApp {
    width: 100%;
    display: grid;
    flex-direction: column;
    align-items: center;
    color: white;
    place-content: center;
    height: 100vh;
    min-height: 400px;

    &_title {
        font-size: 25px;
        margin: 0 40px;
        text-align: center;
        max-width: 589px;
        font-family: $fontClient_Regular;
        @include responsive(phone) {
            max-width: 400px;
            font-size: 15px; 
        }
    }

    &_logoImg {
        max-width: 280px;
        margin: 0 auto 3rem auto;

        @include responsive(phone) {
            max-width: 180px;
        }
    }

    &_card {
        display: flex;
        border: solid 1px white;
        flex-direction: column;
        align-items: center;
        padding: 20px 40px;
        margin: 30px auto 0 auto;
        text-align: center;
        gap: 36px;
        max-width: 589px;
        border-radius: 5px;

        &_appStoreList {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 16px;

            img {
                width: 200px;
            }

            @include responsive(phone) {
                flex-direction: column;
                gap: 24px;
                img {
                    width: 150px;
                }
            }
        }

        &_title {
            font-size: 25px;
            max-width: 400px;
            font-family: $fontClient_Bold;
            @include responsive(phone) {
                font-size: 20px; 
            }
        }

        &_description {
            font-family: $fontClient_Regular;
            font-size: 14px;
        }


    }



}