@import "../../static/sass/config";

.avatarOption {
    display: flex;
    margin: 0 8.875em;
    flex-direction: column;
    margin-bottom: 5em;

    &_header {
        display: flex;
        gap: 2rem;
        align-items: center;
        justify-content: center;
        margin: 4rem 0px;

        &_title {
            font-size: 2.5rem;
            font-family: $fontClient_Regular;

        }
        @include responsive(tab_port) {
            margin: 0 0 2rem;
            gap: 0;
            &_title {
                margin-top: 0;
                font-size: 1.75em;
                margin-bottom: 0;
                margin-top: 0.25em;
            }
         }
    }

    &_backButtom {
        padding: 40px;
        padding-left: 0!important;
        background-color: transparent !important;
        outline: none !important;
        border: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        cursor: pointer;
        width: fit-content;
        i::before{
            transition: transform ease-in-out 300ms, color ease-in-out 300ms;
        }
        &:hover{
            i::before{
                color: $color-primary;
                transform: scale(1.2);
            }
        }

        i{
            font-size: 60px;
        }

        @include responsive(tab_port) {
            i{
                font-size: 5em;
            }
         }
    }


    @include responsive(tab_port) {
        justify-content: center;
        margin: 0em 2.5em;
        margin-bottom: 5em;
     }
  
     @include responsive(phone) {
        justify-content: center;
     }

}
