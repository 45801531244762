@import "../../static/sass/config";

.app-section-load{
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100vh;

   .landing-loader__loadingSpinner{
      position: relative;
   }
}

.main-content.fade-exit-active,.main-content.fade-exit,.main-content.fade-exit-done{
   display: none; //importante
}