@import "../config";
/*OVERRIDE CONTENT OVERVIEW DEFAULT STYLES*/

.contentOverviewWrapper{
    padding: 0px $base-size-6x;
    font-family: $fontClient_Regular;

    @include responsive(tab_medium){
        padding: 0 $base-size-4x;
    }

    @include responsive(phone){
        padding: 0 $base-size-2x;
    }

}

.contentOverviewBackground{
    @include aspectRatio(16/9);
    &::before{
        background-image: linear-gradient(to bottom, rgba($background-color, 1),  rgba($background-color, 0.7), rgba($background-color, 0.8), rgba($background-color, 1));

    }
}

.contentOverviewHeader,
.contentOverviewHedear{
    margin-top: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    @include responsive(tab_medium){
        padding-bottom: 10rem;
    }

    @include responsive(phone){
        padding-bottom: 8rem;
    }

    .contentOverviewExitButtom{
        padding: 0;
        margin-left: -12px;
        transform: scale(1);
        transition: all 0.5s ease-in-out;
        
        [class*=" bi-"]::before {
            text-shadow: 0 0 8px $color-black-75;
            color: $color-white;
            transition: all 0.5s ease-in-out;
            font-size: 4rem;
        }

    }   

    .contentOverviewExitButtom:hover{
        margin-left: -14px;
        transform: scale(1.15,1.15);

        [class*=" bi-"]::before {
            color: $color-primary;
        }

    }

}

.contentOverviewHeader {
    margin-top: 0 !important;
}

.contentOverviewDetail{

    margin-bottom: 1rem;

    .contentOverviewPoster{
        width: 300px;
        max-height: 600px;
        margin-right: 0 !important;
        @include responsive(tab_medium){
            display: none;
        }

        .poster{
            border-radius: 8px !important;
        }

    }

    .contentOverviewInfo {
        width: 75%;

        .contentOverviewCast{
            font-family: $fontClient_Regular;
            display: flex;
            flex-direction: column;
            font-size: 20px;
        }

        .contentOverviewTitle{
            margin-top: 0;
            margin-bottom: 23px;
            font-family: $fontClient_Bold;
            
            h2 {
                font-weight: $font-medium;
                font-size: 3rem !important;
                margin-bottom: 0;
                margin-left: -.18rem;

                @include responsive(tab_medium){
                    font-size: 3rem !important;
                }       

            }

        }

        @include responsive(tab_medium){
            width: 100%;
        }

        .contentOverviewPeriod{
            margin-bottom: 32px;
            align-items: center;
            font-family: $fontClient_Regular !important;
            
            .contentOverviewNetworkBadge{
                font-size: 21px !important;
                margin-left: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 12px;
                background: #ffffff;
                margin-right: 0;
                margin-bottom: 0;
                border-radius: 3px;
                text-transform: uppercase;
                width: auto;
                height: 37px;
                line-height: 37px;
                background-color: transparent;
                border: 1px solid $color-white;
                span{
                    color: $color-white;
                    font-weight: normal;
                    margin-right: 0 !important;
                }
                span:first-child{
                    margin-right: $base-size-half;
                }
                @include responsive(phone){
                    margin: 8px 0;
                }
                @include responsive(tab_medium){
                    margin: 8px 0;
                }
    
            }
            .contentOverviewCategory{
                .contentOverviewRating,.contentOverviewYear,.contentOverviewLenght{
                    font-size: 16px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: auto;
                    height: 37px;
                    border-radius: 5px;
                }

                .contentOverviewRating{
                    background: rgba(#FFFFFF,0.25) 0% 0% no-repeat padding-box !important;
                }

                .contentOverviewYear,.contentOverviewLenght{
                    background-color: transparent;
                }

                
                @include responsive(phone){
                   margin-top: 1rem;
                   margin-right: 1rem;
                }

                @include responsive(tab_medium){
                   margin-right: 1rem;
                }

            }
            .contentOverviewAvailableBadge{
                margin-left: 22px;
                span{
                    font-size: 16px;
                }
                @include responsive(phone){
                    margin: $base-size 0;
                    padding-left: 0 !important;
                }
    
            }
        }

        .contentOverviewActions {
            .contentOverviewActionsPlay,
            .contentOverviewActionsMyList {
                font-family: $fontClient_Medium;
                padding: 24px 16px;
                color: $color-text-button;
                border-radius: .5rem;
                min-width: 150px;
                text-align: center;

                &:hover{
                    color: $color-text-button-hover;
                }

                .contentOverviewActionsMyListTitle,
                .contentOverviewActionsPlayTitle {
                    font-size: 23px;
                    text-align: center;
                    width: 100%;
                }

                .contentOverviewActionsPlayTitle {
                    line-height: 0;
                }

                
                i::before{
                    font-size: 42px;
                    vertical-align: -10px;
                }
            }

            .contentOverviewActionsMyListTitle {
                color: $color-white;
            }
        }
        

        .contentOverviewActions .contentOverviewActionsPlay {
            background-color: $color-primary;

            [class*=" bi-"]::before {
                color: $color-text-button;
            }
        
            &:hover{
                background-color: $color-primary;
                border: 1px solid $color-primary;
            }

            .contentOverviewActionsPlayTitle{
                @include responsive(phone){
                    font-size: $text-size;
                }
    
            }

            i{

                @include responsive(phone){
                    font-size: $text-size-l;
                }
    
            }

        }

        .contentOverviewActions .contentOverviewActionsMyList{
            background-color: $bg-color-my-list-btn;
            max-width: 180px;

            i {
                margin-left: -8px;
            }
        }

        .contentOverviewActionsPlay_disabled_true{
            background-color:  $color-primary !important;
            color: $color-text-button-hover !important;
            opacity: 0.8 !important;
            border: 1px solid transparent !important;
        }
        .contentOverviewDescriptionText{
            font-family: $fontClient_Regular;
            text-align: left !important;
            font-size: 20px !important;
            line-height: 1.5 !important;
            margin: 1.2rem 0 2rem 0;

            @include responsive(tab_medium){
                font-size: $text-size !important;
            }
        
            @include responsive(phone){
                font-size: $text-size !important;
            }

        }

    }

}

.contentOverviewDetail .contentOverviewInfo .contentOverviewActors span, .contentOverviewDetail .contentOverviewInfo .contentOverviewDirector span, .contentOverviewDetail .contentOverviewInfo .contentOverviewGenre span{

    @include responsive(phone){
        font-size: $text-size-m !important;
    }


    a{
        color: $color-primary!important;
        text-transform: none !important;
        font-weight: $font-light !important;
        margin: 0 8px !important;
        text-decoration: none !important; 

        &:hover{
            color: $color-white !important;
        }
    
    }


}

/* CONTENT OVERVIEW LIVE*/

.contentOverviewLiveWrapper {
    padding: 0px $base-size-6x;
    font-family: $fontClient_Regular;

    @include responsive(tab_medium){
        padding: 0 $base-size-4x;
    }

    @include responsive(phone){
        padding: 0 $base-size-2x;
    }

}

.contentOverviewLiveBackground {
    background-image: linear-gradient(to bottom, transparent, rgba(26,26,26, 0.75) 55%, rgba(26,26,26, 1) 85%), var(--contentOverviewLiveBackground-url);
}

.contentOverviewLiveContainer {
    height: auto;
}

.contentOverviewLiveHedear {
    margin-top: 3rem;
    padding-top: 3rem;
    padding-bottom: 20rem;

    @include responsive(tab_medium){
        padding-bottom: 10rem;
    }

    @include responsive(phone){
        padding-bottom: 8rem;
    }


    .contentOverviewLiveExitButtom{
        margin-left: -8px;
        padding: 0;
        transform: scale(1);
        transition: all 0.3s ease-in-out;
        
        [class*=" bi-"]::before {
            text-shadow: 0 0 8px $color-black-75;
            color: $color-white;
            transition: all 0.3s ease-in-out;
            font-size: 4rem;
        }

    }   

    .contentOverviewLiveExitButtom:hover{
        margin-left: -8px;
        transform: scale(1.15,1.15);

        [class*=" bi-"]::before {
            color: $color-primary;
        }

    }

}

.contentOverviewLiveDetail{

    margin-bottom: 2rem;

    .contentOverviewPoster{
        width: 300px;
        max-height: 600px;

        @include responsive(tab_medium){
            display: none;
        }

        .poster{
            border-radius: 8px !important;
        }

    }

    .contentOverviewLiveInfo{
        width: 100%;

        .contentOverviewLiveTitle{

            h2{
                font-weight: $font-medium;
                font-size: $text-size-xxxxl;
                margin-bottom: 0;

                @include responsive(tab_medium){
                    font-size: $text-size-xxxl;
                }       

            }

        }

        @include responsive(tab_medium){
            width: 100%;
        }

        .contentOverviewLiveTitle{
            display: flex;
            margin-bottom: $base-size;
            margin-top: 0px;

            h2{
                margin-bottom: 0;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;  
                overflow: hidden;
            }
    
        }

        .contentOverviewLiveInfoBadge{
            margin-bottom: $base-size;
        }

        .contentOverviewLiveLeagueBadge{
            border-radius: $base-size-half;
        }

        .contentOverviewLiveInfoBadge{
            border-radius: 2px;
            font-weight: $font-bold;
            padding: $base-size-half $base-size;
            font-size: $text-size-l;
            margin-bottom: $base-size;
        }

        .contentOverviewLiveActions .contentOverviewLiveActionsPlay {
            background-color: $color-primary;

            [class*=" bi-"]::before {
                vertical-align: -0.15em;
                color: $color-text-button;
                font-size: 2rem !important;
            }
        
            &:hover{
                background-color: $color-primary-dark;
                border: 1px solid $color-primary-dark;
            }

            .contentOverviewLiveActionsPlayTitle{

                @include responsive(phone){
                    font-size: $text-size;
                }
    
            }

            i{

                font-size: 2rem !important;

                @include responsive(phone){
                    font-size: $text-size-l;
                }
    
            }

        }

        .contentOverviewLivePeriod .contentOverviewLiveNetworkBadge {
            display: flex;
            padding: $base-size-half $base-size;
            background: #ffffff;
            margin-right: $base-size;
            margin-bottom: 0;
            border-radius: 3px;
            text-transform: uppercase;
            font-weight: $font-medium;

            span:first-child{
                margin-right: $base-size-half;
            }

        }

        .contentOverviewLiveDescriptionText{
            font-size: $text-size-l !important;
            line-height: 1.5 !important;
            margin: 0 0 $base-size-2x 0;

            @include responsive(tab_medium){
                font-size: $text-size !important;
            }
        
            @include responsive(phone){
                font-size: $text-size !important;
            }

        }

    }

}

.contentOverviewLiveCast{
    flex-direction: column;
}

.contentOverviewLiveDetail .contentOverviewLiveInfo .contentOverviewLiveActors span, .contentOverviewLiveDetail .contentOverviewLiveInfo .contentOverviewLiveDirector span, .contentOverviewLiveDetail .contentOverviewLiveInfo .contentOverviewLiveGenre span{

    @include responsive(phone){
        font-size: $text-size-m !important;
    }


    a{
        color: $color-primary !important;
        text-transform: none !important;
        font-weight: $font-light !important;
        margin: 0 8px !important;
        text-decoration: none !important; 

        &:hover{
            color: $color-primary-dark !important;
        }
    
    }


}