@import "../../static/sass/config";

.backdrop {
   width: 100%;
   height: 100%;
   position: fixed;
   z-index: 100;
   left: 0;
   top: 0;
   background-color: rgba($color-background, .7);
   transition: all .3s;

   @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
   }
}