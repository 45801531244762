@import "../config";
$tbxContentListItemData-padding: $base-size-2x $base-size;

.tbxContentListCarousel {
    background-color: $color-transparent;

    padding: 0;

    &__heading {
        text-transform: uppercase;
        font-weight: $font-light;
        padding: 2.5rem 16px 0.5rem;
        padding-left: $base-size-6x;
        font-family: $fontClient_Bold;

        @include responsive(tab_medium) {
            padding: $base-size-4x $base-size-2x $base-size;
            font-size: $text-size;
        }

        &::after {
            margin-top: $base-size;

            @include responsive(tab_medium) {
                width: $base-size-3x;
            }

        }
    }

    .swiper-container {
        padding: 0 $base-size-6x $base-size-2x;

        @include responsive(tab_medium) {
            padding: 0 $base-size-2x $base-size-2x;
        }

    }

    &__swiper-content.__imageMode-poster {
        padding-right: $base-size-4x;
        padding-left: 0;
        padding-bottom: 0;

        @include responsive(tab_medium) {
            padding-right: $base-size-2x;
        }

        .tbxContentCard__content-image {
            box-shadow: $base-size $base-size $base-size-2x $color-black-50 !important;
            width: auto !important;
        }

        .tbxContentCard__data {
            padding: $base-size-2x $base-size 0;
        }

        .tbxContentCard__data-title {
            font-weight: $font-light;
            line-height: 1.5rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: $color-dark !important;

            @include responsive(tab_medium) {
                display: -webkit-box;
                font-size: $text-size;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

        }

    }

    .swiper-button-next,
    .swiper-button-prev {

        @include responsive(tab_medium) {
            display: none;
        }

    }

    /* SIMPLE LARGE */

    .tbxContentCard {

        &.__textMode-outer {
            font-family: $fontClient_Regular;
        }

        &__play-icon {

            i {
                font-size: 2.75em;
            }

        }

        &__data-inner {

            &_badge-live {
                font-family: $fontClient_Bold;
                padding: 2px 4px;
                font-weight: 500;
                border-radius: 3px;
                font-size: $text-size-l;
                margin-right: 8px;

                @include responsive(tab_port) {
                    font-size: $text-size-s; 
                }
            }

            &_title {
                font-weight: 400;
                text-transform: none;
            }


        }

    }

    .tbxContentCard.__imageBool-true.__imageMode-simple_large.__textMode-inner .tbxContentCard__content-image::before {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.85));
    }


    .tbxContentCard.__imageMode-poster {

        .tbxContentCard__data-inner,.tbxContentCard__data-inner_playAndFavorite, .tbxContentCard__data-inner_badge-live, .tbxContentCard__content-image::before {
            transition: 500ms ease-in-out opacity, 350ms ease-in-out transform;
            transition-delay: 200ms;
        }
        .tbxContentCard__data-inner_badge-live{
            opacity: 1;
        }
        .tbxContentCard__data-inner, .tbxContentCard__content-image::before {
            transform: translate(0px, 60px);
        }

        .tbxContentCard__data-inner_playAndFavorite, .tbxContentCard__content-image::before {
            opacity: 0;
        }

        .tbxContentCard__data-inner_playAndFavorite_play_button {
            
            .bi-play-circle  {
                &:hover {
                    //background-color: grey !important;
                    border-radius: 50% !important;
                }
    
            }
            
        }

        .tbxContentCard__data-inner_playAndFavorite_favorite_button  {

            .bi-plus-circle, .bi-check-circle {
                &:hover {
                    //background-color: grey !important;
                    border-radius: 50% !important;
                }
    
            }
            
        }

        &.__imageBool-true .tbxContentCard__content-image::before{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.85));
        }

    }

    .tbxContentCard__data-inner_playAndFavorite button i{
        font-size: 2.75em;
    }

    .tbxContentCard.__imageMode-thumb.__textMode-inner._imageBool-true .tbxContentCard__content-image::before {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.75));
    }

}

.tbxContentListCarousel__swiper-content.__imageMode-poster{
    &:hover{
        .tbxContentCard__data-inner_playAndFavorite, .tbxContentCard__content-image::before {
            opacity: 1;
        }
        .tbxContentCard__data-inner{
            transform: translate(0px);
        }
    }
}