@import "../../../static/sass/config";

.profile-item {
   display: grid;
   justify-items: stretch;
   margin: 1.5em;

   // &:not(:last-child) {
   //    margin-right: 2vw;
   // }

   &_containerVisual {
      display: flex;
      flex-direction: column;
      align-items: center;
   }

   &:hover .profile-item_name {
      color: whitesmoke;
   }

   &:hover .profile-item_icon::after {
      border-radius: 50% !important;
   }

   &_icon {
      border-radius: 50% !important;
      height: calc(10vw + 5px);
      max-height: 12.5em;
      max-width: 12.5em;
      min-height: 5.25em;
      min-width: 5.25em;
      position: relative;
      width: calc(10vw + 4px);
      cursor: pointer;

      &::after {
         content: "";
         display: block;
         -webkit-border-radius: 5px;
         -moz-border-radius: 5px;
         border-radius: 5px;
         border: 0.3em solid transparent;
         position: absolute;
         top: 0;
         right: 0;
         bottom: 0;
         left: 0;
      }
   }

   &_iconfallback {
      background: linear-gradient(to bottom, $color-brand-tint 60%, var(--orange) 100%);
   }

   &_name {
      color: #ffffff;
      font-family: $fontClient_Regular;
      font-size: rem(20px);
      font-weight: 500;
      line-height: 1.2em;
      padding: 16px 0;
      padding-top: 24px !important;
      text-align: center;
      cursor: pointer;
   }

   &_operations {
      display: flex;
      gap: 1rem;
      margin-top: 0.5rem;
      justify-content: center;
   }

   &_lock_operation {
      display: flex;
      justify-content: center;
   }

   .item_delete_icon,
   .item_edit_icon,
   .item_lock_icon {
      * {
         transition: ease 300ms;
         transition-delay: 150ms;
      }
      cursor: pointer;
      i {
         font-size: 20px;
         display: inline-block;
         border-radius: 60px;
         border: 1px solid #ffffff;
         padding: 4px 8px;
         &::before {
            color: white;
            transition: ease 300ms;
            transition-delay: 150ms;
         }
      }
      &_onClick {
         transform: scale(1);
         display: block;
         &:hover {
            transform: scale(1.25);
            i {
               background-color: white;
               &::before {
                  color: black;
               }
            }
         }
      }
   }
}
