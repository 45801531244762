

.tbxContentListCarousel {

    &__swiper-content.swiper-slide{

        @include responsive(tab_medium){
            padding-right: $base-size-2x;
        }

    }

    &__swiper-content{
        padding-right: 32px;
        padding-left: 0;
        padding-bottom: 0;

        .tbxContentCard__content-image {
            box-shadow: $base-size $base-size $base-size-2x $color-black-50 !important;
            width: auto !important;
        }

        .tbxContentCard__network-img {
            filter: drop-shadow(0 0 8px rgba(0,0,0,0.5));
        }

        .tbxContentCard__network-content{
            top: 0;
            right: 0;
            padding: $base-size-half;
            text-align: right;
            background: linear-gradient(45deg, rgba(0,0,0,0) 60%, rgba(0,0,0,0.5) 100%);
        }

        .tbxContentCard__data{
            padding: 12px $base-size 0;
        }

        .tbxContentCard__data-title{
            font-weight: $font-light;
            margin-bottom: $base-size-half;
            line-height: 1.5rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            font-family: $fontClient_Regular;
        

            @include responsive(tab_medium){
                display: -webkit-box;
                font-size: $text-size;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;  
                overflow: hidden;            
            }

        }

        .tbxContentCard__data-subtitle{
            font-weight: $font-regular;
            margin-bottom: 0;

            @include responsive(tab_medium){
                display: -webkit-box;
                font-size: $text-size-m;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;  
                overflow: hidden;            
            }

        }

    }

    &__swiper-content--poster {
        padding-right: 32px;
        padding-left: 0;
    }

    
    .tbxContentCard.__imageBool-true.__imageMode-simple_large.__textMode-inner .tbxContentCard__content-image::before {
        background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0), rgba(0,0,0,0.25), rgba(0,0,0,0.85));
    }

    .tbxContentCard.__imageMode-thumb.__textMode-inner._imageBool-true .tbxContentCard__content-image::before {
        background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0), rgba(0,0,0,0.25), rgba(0,0,0,0.75));
    }

}

    
