@import "../config";

.tbxContentEpisodes__container{
    background: $color-transparent !important;
    padding: 0 $base-size-6x;

    @include responsive(tab_medium){
        padding: 0 $base-size-4x;
    }

    @include responsive(phone){
        padding: 0 $base-size-2x;
    }

}

.tbxContentEpisodes_list {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    &__item__disabled__false{
        cursor: pointer;
    }

    &__item__disabled__true .tbxContentCard__image{
        filter: grayscale(100%);
    }

    &__item__info__title{
        font-family: $fontClient_Bold;
    }

    &__item__info__metadata{
        font-family: $fontClient_Bold;
    }

    &__item__info__description{
        font-family: $fontClient_Regular;
    }

}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
    background: $color-black;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    border-color: $color-white;
}

[class^="bi-"]::before, [class*=" bi-"]::before {
    vertical-align: -0.2em;
    color: $color-white;
}

.tbxContentEpisodes__container .tbxContentCard__content-image {
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
}

.tbxContentEpisodes_list__item__info__description{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.tbxContentEpisodes__header{
    justify-content: flex-start;
    &__title{
        margin-top: 16px !important;
        font-family: $fontClient_Regular;
        font-size: 25px !important;
        margin: 0;
        font-weight: 600;
    }
    
    &_onlySeason{
        font-family: $fontClient_Regular !important;
        font-weight: 400;
        font-size: 20px;
        margin: auto 16px;
        margin-top: 17px;
    }
    &_seasonSelector{
        margin: 0 2.063em;
        width: 212px;
        height: 50px;
        
        .tbxContentEpisodes__buttonsTitle{
            display: flex;
            justify-content: space-between;
            .tbxContentEpisodes__buttonsSeason{
                font-family: $fontClient_Medium !important;
                font-size: 20px;
            }
            .tbxContentEpisodes__downIcon{
                font-size: 20px;
                &::before{
                    font-weight: 900 !important;
                }
            }
        }
        .dropdown-menu a{
            padding: 0.5rem  1.25rem;
        }
    
    }
}



.tbxContentEpisodes__buttonsSeason{
    font-family: $fontClient_Regular !important;
}


.tbxContentEpisodes_list__item__info{

    &__title{
        font-size: $text-size;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }

    &__metadata{
        font-size: $text-size;
    }

}