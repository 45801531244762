@import "../../static/sass/config";

.tbxNotificationModal-container {
    .tbxNotificationModal-confirmDeleteProfile {
        background-color: black;
    }

    .tbxNotificationModal-header{
        text-transform: uppercase;
        font-size: 1.5em;
        font-weight: 600;
        .tbxNotificationModal-title{
            margin: 0;
            font-size: 1.4rem;
        }
    }
    .tbxNotificationModal-exitButton{
        position: fixed;
        top: 5em;
        right: 5em;
        background: transparent;
        border: transparent;
        .tbxNotificationModal-exitButton-icon{
            font-size: 2em;
            &::before{
                color:rgb(102, 102, 102)
            }
        }
        &:hover{
            .tbxNotificationModal-exitButton-icon::before{
                transition: color 200ms ease-in-out;
                color:rgb(136, 136, 136)
            }
        }
    }
    .tbxNotificationModal-body{
        font-size: 1.3rem;
        font-weight: 300;
        max-width: 50em;
    }
    .tbxNotificationModal-logo{
        max-width: 10em;
        max-height: 6em;
    }
    .tbxNotificationModal-summary{
        color: #FFFFFFB3;
        list-style:none ;
    }
    .tbxNotificationModal-errorText{
        margin-top: 1em;
    }
    
    .tbxNotificationModal-footer{
        display: flex;
        flex-direction: column;
        gap: 1em;
        button{
          width: 24em;  
          height: 2.5em;
          border-radius: 4px;
          text-transform: uppercase;
          color: #FFFFFF;
        }
        .tbxNotificationModal-firstButton{
            background-color: $color-primary !important;
            border: 1px solid $color-primary !important;
            color: $color-text-button !important;
            font-family: $fontClient_Bold !important;
        }
        .tbxNotificationModal-secondButton{
            background-color: $color-dark !important;
            border: 1px solid #FFFFFF;
            font-family: $fontClient_Regular !important;
        }
        
    }


    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto 3em;
    font-size: 1em;
    z-index: 99999999;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    gap: 2.5em;
    height: min-content;
    text-align: center;

}




.tbxNotificationModal-errorCode{
    &-CP-006,&-CS-011,&-CP-015,&-CP-037,&-CP-137,&-GE-001,&-CL-001{
        max-width: 32em;
        margin: auto;
        background: #000000b4 0% 0% no-repeat padding-box;
        padding: 1.5em;
        box-shadow: 0px 8px 16px #00000080;
        border: 1px solid #707070;
        border-radius: 8px;
        opacity: 1;
        min-height: 16em;
        animation: zoomModal 500ms ease-in-out;
        transition: transform 300ms ease-in-out;
        &:hover{
            transform: scale(1.01);
        }
        .tbxNotificationModal-exitButton{
            position: absolute;
            top: 1em;
            right: 1em;
            .tbxNotificationModal-exitButton-icon{
                font-size: 1.5em;
            }
        }
        .tbxNotificationModal-body{
            padding: 0 1em;
        }
        .tbxNotificationModal-htmlText{
            padding: 0 1em;
        }
        .tbxNotificationModal-logo{
            max-width: 8em;
        }
    }
}


@keyframes zoomModal {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    65% {
        opacity: 0.7;
        transform: scale(1.05);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
 }