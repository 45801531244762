@import "../../static/sass/config";

footer {
  margin-top: 4em;
  background-color: transparent;
  padding: 36px 0px 36px;
  border-top: 1px solid rgba($color-white, 0.3);
  color: #b3b3b3;
  height: 270px;
  display: none !important;

  .footer-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    .footer-linksLegales-links{
      display: flex;
      gap: 8px;
      *{
        color: $color-white;
      }
    }
    .footer-socialMedia{
      display: flex;
      gap: 34px;
      a{
        i{
          font-size: 24px;
        }
      }
    }

    .footer-rightsReserved{
      font-family: $fontClient_Regular;
    }
    
  }

}
