$html-font-size: 16px;

/* Media query manager */
@mixin responsive($breakpoint) {

   @if $breakpoint ==phone_small {
      @media only screen and (max-width: 28.12em) {
         // 600px --> 600 / 16
         @content
      }

      ;
   }

   @if $breakpoint ==phone {
      @media only screen and (max-width: 37.5em) {
         // 600px --> 600 / 16
         @content
      }

      ;
   }

   @if $breakpoint ==tab_medium {

      // 980px 
      @media only screen and (max-width: 61.25em) {
         @content
      }

      ;
   }

   @if $breakpoint ==tab_port {
      @media only screen and (max-width: 74em) {
         // 1184px 
         @content
      }

      ;
   }

   @if $breakpoint ==lg_desktop {
      @media only screen and (max-width: 102em) {
         // 1633px 
         @content
      }

      ;
   }
}

@function stripUnit($value) {
   @return $value / ($value * 0 + 1);
}

@function rem($pxValue) {
   @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}

//SPINNER
@mixin spinner($colorPrimary, $colorSecondary, $width, $height: $width) {
   width: $width;
   height: $height;
   border-radius: 50%;
   box-shadow: 0px 0px 5px 2px rgba($colorPrimary, 0.5), 4px 1px 0px $colorSecondary;
   animation-duration: 800ms;
   animation-iteration-count: infinite;
   animation-timing-function: linear;
   animation-name: rotate;
   background-color: transparent;


   @keyframes rotate {
      100% {
         transform: rotate(360deg)
      }
   }
}


/* aspectRatio */
@mixin aspectRatio($aspect-ratio) {
   $aspect-ratio-v: var($aspect-ratio, 0);

   aspect-ratio: $aspect-ratio;

   // Fallback using padding hack
   @supports not (aspect-ratio: $aspect-ratio-v) {
      /*&::before {
         float: left;
         padding-top: calc(100% / #{$aspect-ratio-v});
         content: '';
      }

      &::after {
         display: block;
         content: '';
         clear: both;
      }*/
   }
}