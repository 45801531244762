.homeSection {

    &__results-loader {
        height: 500px;
  
        .tbx-LoadingSpinner_container {
           position: relative;
        }
     }

   .infinite-scroll-component {
      overflow: unset !important;
   }

}