@import "../config";

$light-color: #ffffff;
$gray-color: #d9d9d9;

/**** GENERAL ****/

body {
  background-color: $light-color !important;
}

/**** PIN MODAL ****/

.pin-field,
.pinCodeModal {
  color: $light-color;
}

/**** ERRORS MODAL ****/

.tbxContentModal {
  .tbxNotificationModal {
    &-errorCode {
      &-CS-011,
      &-CP-006,
      &-CP-015,
      &-CP-037,
      &-CP-137,
      &-GE-001,
      &-CL-001 {
        background: $light-color;
        color: $color-dark;
      }
    }
  }
}

/**** CAROUSEL ****/

.tbxContentListCarousel__heading,
.tbxContentGrid__heading,
.contentRelatedTitle,
.tbxContentEpisodes__header__title {
  color: $color-dark !important;

  &::after {
    background-color: $color-primary !important;
  }
}

.nav-link {
  .tbxContentListCarousel__heading {
    &:hover {
      color: $color-primary !important;

      .arrow {
        &::before {
          color: $color-primary !important;
        }
      }
    }

    .arrow {
      &::before {
        color: $color-dark !important;
      }
    }
  }
}

.tbxContentListCarousel {
  &__swiper {
    &-content {
      &.__imageMode-thumb {
        .tbxContentCard__data-title {
          color: $color-dark;
        }
      }
    }
  }
}

.tbxContentGrid {
  &__gallery-THUMB {
    .tbxContentCard__data-title {
      color: $color-dark;
    }
  }

  .tbxContentGridFilter {
    a {
      color: $color-dark;

      &:hover {
        color: $color-primary;
      }
    }
  }
}

/**** HERO BANNER ****/

.tbxBanner {
  .item {
    .bannerHeroImage::before {
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba($color: $light-color, $alpha: 0.1),
        rgba($color: $light-color, $alpha: 0.7),
        $light-color
      );
    }
  }
}

/**** HEADER ****/

.navigation {
  background-color: $light-color;

  .active-link {
    &.isImg_false {
      background-color: $color-primary;
    }
  }

  .pseudo-link {
    color: $color-dark !important;

    &.isImg_false {
      margin: 0 0.7rem;
      text-transform: unset;

      &:hover {
        background-color: $color-primary;
      }
    }
  }

  &.black {
    background-color: $light-color;
    box-shadow: 0 4px 10px rgba($color: $color-dark, $alpha: 0.3);
  }

  &__container {
    &--searchLogo {
      g {
        fill: $color-dark;
      }
    }
  }

  .loginButton {
    color: $color-dark;

    .accountLogo {
      path {
        fill: $color-dark;
      }
    }
  }
}

.burgerMenuContainer {
  .bm-burger-bars {
    background: $color-dark !important;
  }
}

.dropdown-content__container__openMenu svg {
  fill: $color-dark;
}

/**** SEARCH PAGE ****/

.searchSection {
  &__inputGroup,
  &__searchBar {
    background-color: $light-color !important;
  }

  &__inputGroup {
    .searchSection__searchBar {
      &.form-control {
        color: $color-dark !important;

        &:focus {
          color: $color-dark !important;
        }

        &::placeholder {
          color: $gray-color !important;
        }
      }
    }

    &-clearIcon {
      fill: $color-dark !important;
    }
  }

  .no-results {
    &__container {
      &__text {
        color: $color-dark !important;
      }
    }
  }
}

/**** FICHA (CONTENT OVERVIEW) ****/

.ContentOverviewSection {
  .contentOverviewInfo {
    .contentOverviewTitle,
    .contentOverviewDescriptionText,
    .contentOverviewCast span,
    .contentOverviewCategory * {
      color: $color-dark !important;
    }

    .contentOverviewPeriod {
      .contentOverviewCategory {
        .contentOverviewRating {
          background: rgba($color: $color-dark, $alpha: 0.3) !important;
          color: $color-white !important;
        }
      }
    }

    .contentOverviewNetworkBadge {
      background-color: rgba($color: $light-color, $alpha: 0.1) !important;
      border: 1px solid $color-dark !important;

      span {
        color: $color-dark !important;
      }
    }

    .contentOverviewCast {
      .contentOverviewLabel {
        a {
          &:hover {
            color: $color-dark !important;
          }
        }
      }
    }

    .contentOverviewActionsPlay {
      &:hover {
        background-color: $color-primary-dark !important;
        border: 1px solid $color-primary-dark !important;
      }
    }
  }

  .contentOverviewBackground {
    &::before {
      background: linear-gradient(
        to bottom,
        transparent,
        rgba($color: $light-color, $alpha: 0.6) 0%,
        rgba($color: $light-color, $alpha: 1) 100%
      ) !important;
    }
  }
}

.tbxContentEpisodes {
  &__header {
    &_onlySeason {
      color: $color-dark;
    }

    &_seasonSelector {
      .dropdown-toggle,
      .dropdown-toggle:focus,
      .dropdown-toggle[aria-expanded="true"] {
        background-color: $light-color !important;
        border-color: $color-dark;
        color: $color-dark !important;
      }

      .dropdown-menu {
        background-color: $light-color;
        border-color: $color-dark;
      }

      .dropdown-item {
        &.active,
        &:hover {
          background-color: $gray-color !important;
          color: $color-primary !important;
        }
      }
    }
  }

  &_list__item__info__title {
    color: $color-dark;
  }

  &__downIcon::before {
    color: $color-dark;
  }
}

/**** FICHA SPORT (CONTENT OVERVIEW FUTURE) ****/

.ContentOverviewLiveSection {
  .contentOverviewBackground {
    &::before {
      background: rgb(255, 255, 255) !important;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0.6) 100%
      ) !important;
    }
  }

  .contentOverviewInfo {
    .contentOverviewTitle,
    .contentOverviewDescriptionText,
    .contentOverviewCast span,
    .contentOverviewCategory * {
      color: $color-dark !important;
    }

    .contentOverviewPeriod {
      .contentOverviewCategory {
        .contentOverviewRating {
          background: rgba($color: $color-dark, $alpha: 0.3) !important;
          color: $color-white !important;
        }
      }
    }

    .contentOverviewNetwork {
      background-color: rgba($color: $light-color, $alpha: 0.1) !important;
      border: 1px solid $color-dark !important;

      span {
        color: $color-dark !important;
      }
    }

    .contentOverViewLiveLabel {
      i {
        &::before {
          color: $color-dark;
        }
      }
    }

    .contentOverviewCast {
      .contentOverviewLabel {
        a {
          &:hover {
            color: $color-dark !important;
          }
        }
      }
    }

    .contentOverviewActionsPlay {
      &:hover {
        background-color: $color-primary-dark !important;
        border: 1px solid $color-primary-dark !important;
      }
    }
  }

  .contentOverviewCountdown {
    color: $color-dark;

    .countdownBox {
      .countdownNumber,
      .countdownLabel {
        color: $light-color;
      }
    }
  }

  .contentOverviewAvailableBadge {
    color: $color-dark;
  }
}

/**** FOOTER ****/

/**** ERROR ****/

.tbxHandleError,
.tbxDownloadApp {
  background-color: $light-color;

  * {
    color: $color-dark;
  }

  &-summary {
    color: $color-primary;
  }
}
