@import "../../static/sass/config";

.navigation {
  background-color: transparent;
  z-index: 100;
  grid-column: 1 / 13;
  top: 0;
  width: 100%;
  transition-timing-function: ease-in;
  transition: all 1s;

  &.black {
    background-color: $color-secondary;
  }

  &__container {
    background-color: transparent;
    margin-left: 3.8rem;
    display: flex;
    height: 6.7rem;
    justify-content: flex-start;
    align-items: center;
    list-style: none;

    @include responsive(phone) {
      margin-left: 1rem;
    }

    // a:first-child {
    //    padding-left: .2%;
    //    color: $color-red;
    //    font-size: 4rem;
    // }

    &--bellLogo {
      cursor: pointer;
      height: 2.2rem;
      margin-left: 1rem;
      margin-right: 1rem;
      width: 2.2rem;

      @include responsive(phone) {
        display: none;
      }
    }

    &--searchLogo {
      cursor: pointer;
      height: 2.2rem;
      margin-left: 1rem;
      margin-right: 1rem;
      width: 2.2rem;

      g {
        transition-timing-function: ease-in;
        transition: fill 0.3s;
      }

      &:hover g {
        fill: $color-text-button !important;
      }

      @include responsive(tab_port) {
        padding-right: 0.1rem;
      }
    }

    &--userLogo {
      margin-top: 2rem;
      border-radius: 5px;
      cursor: pointer;
      background-color: rgb(201, 199, 78);
      height: 3rem;
      width: 3rem;
      margin-right: 0.5rem;

      @include responsive(tab_port) {
        margin-right: 3rem;
      }

      @include responsive(phone) {
        display: none;
      }

      &:hover > .dropdownContent {
        transition: all 0.4s;
        opacity: 1;
        visibility: visible;
      }
    }

    &--downArrow {
      cursor: pointer;
      fill: #fff;
      height: 1rem;
      width: 1rem;
      margin-right: 5.8rem;

      @include responsive(tab_port) {
        display: none;
      }
    }

    &--downArrow-2 {
      display: none;
      cursor: pointer;
      fill: #fff;
      height: 1rem;
      width: 1rem;

      @include responsive(tab_port) {
        height: 1.5rem;
        width: 1.5rem;
        display: none;
      }

      @include responsive(phone) {
        display: none;
      }
    }

    &--logo {
      height: 6em;

      @include responsive(phone) {
        height: 1.25rem !important;
        margin-bottom: 4px;
        padding-right: 0rem;
      }
    }

    &-link.isImg_false {
      font-weight: 500;
      font-size: 1.5rem;
      color: rgb(221, 221, 221);
      text-decoration: none;
      margin-right: 2rem;
      transition: all 0.2s;

      &:last-child {
        padding-right: 2.6rem;
      }

      &:hover {
        color: $color-text-button;
      }
    }

    &-link.isImg_true {
      img {
        height: 30px;
        margin: 0 8px;
      }
    }

    &--left {
      flex-grow: 1;
      color: #fff;
      padding-right: 2rem;
      display: flex;
      justify-content: flex-end;

      &__input {
        font-size: 1.4rem;
        border: none;
        color: #fff;
        outline: none;
        width: 0px;
        padding: 10px;
        padding-right: 2rem;
        background: $color-background;
        border: 1px solid #fff;
        transition: width 0.5s;
        cursor: pointer;
        opacity: 0;

        &:focus {
          padding-left: 4rem;
          width: 22rem;
          cursor: text;
          opacity: 1;

          @include responsive(phone) {
            width: 16rem;
          }
        }
      }
    }
  }

  .navLinkContainer {
    margin-top: 10px;
  }

  .logo {
    width: 1.8rem;
    height: 1.8rem;
    transform: translateX(2.4rem) translateY(1rem);
    cursor: pointer;
  }

  .pseudo-link {
    cursor: pointer;

    @include responsive(tab_port) {
      display: none;
    }
  }

  .active-link.isImg_false {
    color: $color-text-tint;
  }

  .loginButton {
    background-color: transparent;
    outline: none !important;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-family: $fontClient_Regular !important;
    margin-right: 1rem;

    @include responsive(tab_port) {
      margin-right: 0;
    }

    .accountLogo {
      path {
        transition-timing-function: ease-in;
        transition: fill 0.3s;
        fill: $color-white;
      }

      height: 24px;
      width: 24px;
    }

    &:hover {
      background-color: $color-primary;
      color: $color-text-button;

      .accountLogo path {
        fill: $color-text-button;
      }
    }

    &:focus {
      background-color: $color-primary;
      outline: none !important;
      box-shadow: none !important;
      color: $color-text-button;

      .accountLogo path {
        fill: $color-text-button;
      }
    }
  }

  .textLoginButton {
    padding-top: 0.25rem;

    @include responsive(phone) {
      display: none;
    }
  }

  &.sticky {
    padding: 1rem 0;
    position: sticky !important;
    top: 0;

    .navigation__container {
      height: fit-content;

      .logoContainer {
        @include responsive(lg_desktop) {
          margin-right: 1rem;
        }
      }
    }
  }

  .dropdownCountries {
    width: 162px;

    .dropdown-toggle {
      background-color: $color-white !important;
      border: 1px solid $color-dark;
      color: $color-dark;
    }

    &__downIcon {
      &::before {
        color: $color-dark;
      }
    }

    &__country {
      &:hover,
      &.active {
        background-color: $bg-epg-toggle-current-color;
        color: $color-primary;
      }
    }

    &:focus,
    &.show {
      .dropdown-toggle {
        border-color: $color-dark;
        color: $color-dark;
      }
    }

    .dropdown-menu {
      border-top: none;
      border-color: $color-dark;
    }
  }
}

.navigation.subSection-false ~ .tbxAppRoutes {
  .hstack-container .tbxContentListCarousel:first-child {
    margin-top: 0;
  }
}

.navigation.subSection-true ~ .tbxAppRoutes {
  .hstack-container .tbxContentListCarousel:first-child {
    margin-top: 0;
  }
}

.logoContainer {
  height: 3rem;
}
