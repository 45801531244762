@import "../config";


.tbxContentCard {

    .tbxContentCard__data-inner_badge-live {
        background: $color-primary;
        border-radius: 2px;
        color: $color-white !important;
        font-weight: normal;
        font-size: 0.75em;
        margin-right: 26px;
        text-transform: uppercase;
        font-family: $fontClient_Regular;
        align-self: flex-start;
        
    }

    .trailerOverview_container {

        background-color: $color-gray  !important;

        .trailerOverview__data-title,
        .trailerOverview__data-subtitle,
        .trailerOverview__buttons button i::before {
            color: $color-white-75;
        }

        .trailerOverview__buttons_play {

            .bi-play-circle {

                &:hover {
                    //background-color: grey !important;
                    border-radius: 50% !important;
                }

            }

            .bi-check-circle {

                &:hover {
                    //background-color: grey !important;
                    border-radius: 50% !important;
                }

            }
        }

        .trailerOverview__buttons_favorite {

            .bi-plus-circle,
            .bi-check-circle {

                &:hover {
                    //background-color: grey !important;
                    border-radius: 50% !important;
                }

            }
        }
    }
}