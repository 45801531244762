// DEFAULT COLORS
$color-dark: #000000;
$color-background: #000000;
$color-background-modal: #000000;
$color-brand-tint: #79d6df;
$color-white: rgb(255, 255, 255);
$color-green: #1cb831;
$color-green-modal: rgb(92, 223, 92);
$color-modal-grey: rgb(172, 171, 171);
$color-modal-grey-2: rgb(122, 122, 122);
$color-grey-typography: rgb(170, 170, 170);
$color-red: #e21221;
$color-red-2: #f80616de;
$color-blue: #007bff;
$color-dark-blue: #082452;
$color-light-blue: rgb(15, 61, 129);
$color-light-blue-2: #2b2da5;
$color-purple: #782da3;

// TBX-WIDGETS VARS OVERRIDES:
$tbx-widget-title-color: $color-white;
$tbxContentListItemData-padding: 8px 16px;

// CLIENT CUSTOM COLORS
$color-primary: #CA1818;
$color-primary-dark: #960a0a;
$color-secondary: $color-dark;
$background-color: #000000;
$color-hover: $color-primary;
$background-component-color: #181818;
$bg-epg-toggle-color: $color-primary-dark;
$bg-epg-toggle-current-color: #D9D9D9;

// CLIENT VARS:
$color-disabled: $color-modal-grey-2;
$color-text-tint: $color-brand-tint;
$landing-loader-transition: opacity 0.1s ease-in-out;
$landing-loader-opacity: 1;
$navbar-height: 100px;
$navbar-height-subSection: 12rem;
$border-radius-button: 0.313rem;

// BUTTONS
$color-text-button-hover: $color-white;
$color-text-button: $color-white;
$bg-color-my-list-btn: $color-secondary;

$tbx-player-main-color: $color-primary !important;
