@import "../../static/sass/config";

.profile-manager {
   align-content: center;
   align-items: center;
   background-color: $color-background;
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   justify-content: flex-start;
   min-height: 100vh;
   font-size: 0.75em;
   // margin-top: 5rem;

   &_header {
      position: relative;
      background: transparent linear-gradient(0deg, #00000000 0%, #000000d9 100%) 0% 0% no-repeat padding-box !important;
      opacity: 1;
      width: 100%;
      text-align: center;
      margin-bottom: 7rem;

      @include responsive(tab_port) {
         margin-bottom: 5rem;
      }

      @include responsive(phone) {
         margin-bottom: 5rem;
      }

      img {
         width: 265px;
         height: 100px;

         @include responsive(tab_port) {
            width: 245px;
         }
      }

      &__button {
         position: absolute;
         height: 100%;
         left: 4rem;

         @include responsive(tab_port) {
            left: 0rem;
            top: 1rem;
         }
      }
   }

   &_animation {
      width: 100%;
      animation: fadeInWhileScalingDown 500ms forwards;
   }
   &__title {
      font-family: $fontClient_Regular;
      font-size: rem(30px);
   }

   &_container_loadingSpinner{
      height: 700px;
      .profile-manager_loadingSpinner{
         margin: auto;
         height: 120px;
         width: 120px;
      }
   }


   &_form {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 2em;

      @include responsive(tab_port) {
         flex-direction: column;
         align-items: center;
         margin-left: 2rem;
         margin-right: 2rem;
         margin-top: 1em;
         gap: 2em;
         // width: 100%;
      }

      @include responsive(phone) {
         flex-direction: column;
         align-items: center;
         margin-left: 2rem;
         margin-right: 2rem;
      }
   }

   &_editbutton {
      background-color: transparent;
      border: 1px solid grey;
      color: grey;
      cursor: pointer;
      display: block;
      font-size: max(1.2em, 2em);
      letter-spacing: 2px;
      margin: 3em auto 1em auto;
      padding: 0.5em 1.5em;
      text-transform: uppercase;

      &:hover {
         border: 1px solid whitesmoke;
         color: whitesmoke;
      }
   }

   .profileManagerForm_avatar {
      margin-bottom: 1rem;
   }
   .profileManagerForm_avatar-item_icon {
      max-width: 95px;
      max-height: 95px;
   }

   &_list {
      align-content: center;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 16px;
      gap: 4.5rem;
   }

   @keyframes fadeInWhileScalingDown {
      0% {
         opacity: 0;
         transform: scale(1.1);
      }
      100% {
         opacity: 1;
         transform: scale(1);
      }
   }

   @media screen and (min-width: 1020px) {
      .mouth {
         border: solid 6px #fff;
         border-color: #fff transparent transparent transparent;
      }
   }

   @include responsive(tab_port) {
      justify-content: flex-start;
      width: 100%;
   }

   @include responsive(phone) {
      justify-content: flex-start;
      width: 100% !important;
   }
}

.pinCodeModalContainer {
   display: grid;
   justify-content: center;
   margin-left: 20rem;
   margin-right: 20rem;
   margin-top: 15rem;
   gap: 1rem;
   background-color: black;
   padding: 2rem;
   border: 1px solid;

   .buttonsOptions {
      display: flex;
      justify-content: end;
      gap: 1rem;
   }

   .errorMessageText {
      color: red;
   }
   .closeAction {
      float: right;
      color: grey;
      font-size: 2rem;
   }

   [class*=" bi-x"]::before {
      vertical-align: 0.3rem !important;
   }

   @include responsive(tab_port) {
      margin-left: 0rem;
      margin-right: 0rem;
   }

   @include responsive(phone) {
      margin-left: 0rem;
      margin-right: 0rem;
   }
}

.disabled-modal {
   pointer-events: none;
   opacity: 0.4;
}

.disabledForm {
   pointer-events: none;
   opacity: 0.4 !important;
}
