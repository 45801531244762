@import "../../../static/sass/config";

.profile-option {

   &_header {
      background: transparent linear-gradient(0deg, #00000000 0%, #000000D9 100%) 0% 0% no-repeat padding-box !important;
      opacity: 1;
      width: 100%;
      text-align: center;
      margin-bottom: 6.875em;

      img {
         margin-top: 25px;
         width: 128px;
         height: 50px;
      }
   }

   &_animation {
      width: 100%;
      animation: fadeInWhileScalingDown 500ms forwards;
   }

   &_title {
      text-align: center;
      font-family: $fontClient_Regular;
      font-size: rem(30px);
      
   }

   &_editbutton {
      background-color: transparent;
      border: 1px solid grey;
      color: grey;
      cursor: pointer;
      display: block;
      font-size: max(1.2em, 2em);
      letter-spacing: 2px;
      margin: 3em auto 1em auto;
      padding: 0.5em 1.5em;
      text-transform: uppercase;

      &:hover {
         border: 1px solid whitesmoke;
         color: whitesmoke;
       }
   }

   &_list {
      align-content: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 1.5em;
      margin-bottom: 4em;
      gap: 4.5em !important;

      @include responsive(tab_port) {
         
      }
   
      @include responsive(phone) {
         
      }

      .profile-item_add {
         .profile-item_containerVisual .profile-item_icon {
            background-color: transparent;
            border: 1px solid #ffffff;
         }
      }
   }

   @keyframes fadeInWhileScalingDown {
      0% {
        opacity: 0;
        transform: scale(1.1);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
   }

   @media screen and (min-width: 1020px) {
      .mouth {
        border: solid 6px #fff;
        border-color: #fff transparent transparent transparent;
      }
   }
}