@import "../../static/sass/config";

.profileManagerForm_avatar {
   position: relative;
	display: inline-block;
   margin-top: 1rem;
   //  &__image {
   //      width: 100px;
   //      display: block; 
   //  }

   
    &-item {
        &:not(:last-child) {
            margin-right: 2vw;
         }
      
         &:hover .avatar-item_name {
            color: whitesmoke;
         }
      
         &:hover .avatar-item_icon::after {
            border-radius: 50% !important;
         }
      
         &_icon {
            border-radius: 50% !important;
            height: calc(8vw + 5px);
            display: inline-block;
            width: calc(8vw + 5px);
            background: #FFFFFF1A 0% 0% no-repeat padding-box;
            opacity: 1; 
            position: relative;
         }
      
         &_iconfallback {
            background: $color-primary;
         }
      
         &_name {
            color: grey;
            font-size: max(0.9em, 1.5em);
            font-weight: 500;
            line-height: 1.2em;
            margin: 16px 0;
            min-height: 1.8em;
            text-align: center;
         }

    } 
    
    &__edit_icon {
      background-color: black;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      padding: 0.7em 0.8em;
      position: absolute;
      cursor: pointer;
      right: -12px;
      bottom: 0;
      border-style: groove;
      border-color: white;
      transition: background-color ease-in-out 300ms, border-color ease-in-out 300ms, transform ease-in-out 300ms;
      transition-delay: 200ms;
      transform: scale(1);
      display: flex;
      justify-content: center;
      align-items: center;

      i{
         font-size: 1.25em;
         &::before {
            transition: color ease-in-out 300ms;
            transition-delay: 200ms;
         }
      }

  
      &:hover {
         background-color: white;
         transform: scale(1.15);
         border-color: transparent;

        i::before {
            color: black; }
      }
  }

  .avatar-selectable {
     cursor: pointer;
  }

   @include responsive(tab_port) {
      margin-top: 0rem;
   }

   @include responsive(phone) {
      margin-top: 0rem;
   }
}

.avatarList_avatar {
   position: relative;
   display: inline-block;
   //  &__image {
   //      width: 100px;
   //      display: block; 
   //  }

    &-item {
        &:not(:last-child) {
            margin-right: 2vw;
         }
      
         &:hover .avatarList_avatar-item_name {
            color: whitesmoke;
         }
      
         &:hover .avatarList_avatar-item_icon::after {
            border-radius: 50% !important;
         }
      
         &_icon {
            border-radius: 50% !important;
            height: calc(5vw + 50px);
            display: inline-block;
            width: calc(5vw + 50px);
            background: #FFFFFF1A 0% 0% no-repeat padding-box;
            opacity: 1; 
            position: relative;
            // &::after {
            //    content: '';
            //    display: block;
            //    -webkit-border-radius: 5px;
            //    -moz-border-radius: 5px;
            //    border-radius: 5px;
            //    border: 0.3em solid transparent;
            //    position: absolute;
            //    top: 0;
            //    right: 0;
            //    bottom: 0;
            //    left: 0;
            // }
            @include responsive(tab_port) {
               height: calc(8vw + 80px);
               width: calc(8vw + 80px);
            }
         }
      
         &_iconfallback {
            background: $color-primary;
         }
      
         &_name {
            color: grey;
            font-size: max(0.9em, 1.5em);
            font-weight: 500;
            line-height: 1.2em;
            margin: 16px 0;
            min-height: 1.8em;
            text-align: center;
         }

    } 
    
   /*&__edit_icon {
      background-color: black;
      border-radius: 1000%;
      padding: 0.7em 0.8em;
      position: absolute;
      cursor: pointer;
      right: 0;
      bottom: 0;
      border-style: groove;
      border-color: white;
      transition: background-color ease-in-out 300ms, border-color ease-in-out 300ms, transform ease-in-out 300ms;
      transition-delay: 200ms;
      transform: scale(1);

      i{
         font-size: 1.25em;
         &::before {
            transition: color ease-in-out 300ms;
            transition-delay: 200ms;
         }
      }

  
      &:hover {
         background-color: white;
         transform: scale(1.15);
         border-color: transparent;

        i::before {
            color: black; }
      }
  }*/

  .avatarList_avatar-selectable {
     cursor: pointer;
  }

   @include responsive(tab_port) {
      margin-top: 0rem;
   }

   @include responsive(phone) {
      margin-top: 0rem;
   }
}