.notFound{
    height: 100vh;
    width: 100vw;
    color: white;

    &_logo{
        position: relative;
        top: 30vh;
        left: 0;
        right: 0;
        margin: auto;
        height: 5em;
        width: 100vw;
        text-align: center;
    }
    &_title{
        position: relative;
        top: 35vh;
        left: 0;
        right: 0;
        margin: auto;
        height: min-content;
        width: 100vw;
        text-align: center;
        padding: 1em;
    }
}