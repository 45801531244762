@import "../../static/sass/config";

.landing-loader {
   background-color: transparent;
   padding-top: 10rem;
   padding-bottom: 4rem;
   opacity: $landing-loader-opacity;
   text-align: center;
   transition: $landing-loader-transition;
   height: 100vh;

   .tbx-LoadingSpinner_container.landing-loader__loadingSpinner{
      position: relative;
   }

   /*&__spinner {
      color: $color-brand-tint;
      transform: scale(3);
   }*/

   @include responsive(phone) {
      padding-top: 10rem;
   }

   .container {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;

      .container-item-spinner{
         margin-top: 4rem;
         @include responsive(tab_medium) {
            height: 5em;
            width: 8em;
         }
      }

      .container-item-image {
         margin: 2rem;
         img{
            height: 5em;
            @include responsive(tab_medium) {
               height: 2em;
            }
         }
      }
   }
}