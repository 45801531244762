@import "../../static/sass/config";

.dropdown-content {
   display: flex;
   flex-direction: column;
   //   align-items: center;
   opacity: 0;

   color: #FFF;
   transition-delay: .5s;
   padding-left: 1rem;
   padding-top: 1.8rem;
   visibility: hidden;
   height: 12rem;
   border: 1px solid rgb(73, 73, 73);
   width: 15rem;
   background-color: rgba(#000, .9);
   transform: translateY(4.6rem) translateX(-11rem);

   &__container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-content: center;

      &:hover .dropdown-content {
         transition: all .4s;
         opacity: 1;
         visibility: visible;
      }

      &__openMenu {
         background: none;
         border: 0;
         color: inherit;
         /* cursor: default; */
         font: inherit;
         line-height: normal;
         overflow: visible;
         padding: 0;
         -webkit-user-select: none;
         /* for button */
         -webkit-appearance: button;
         /* for input */
         -moz-user-select: none;
         -ms-user-select: none;

         width: 30px;
         height: 30px;


         svg {
            transition: transform 200ms ease-in-out;
            height: 100%;
            width: 100%;
            fill: white;
         }

      }

      &--userLogoDefault {
         display: flex;
         align-items: center;
         cursor: pointer;


         img {
            width: 30px;
            border-radius: 50% !important;
         }

         @include responsive(tab_port) {
            margin-right: 1rem;
         }

         @include responsive(phone) {
            margin-right: 1rem;
         }

         &:hover>.dropdown-content {

            transition: all .4s;
            opacity: 1;
            visibility: visible;
         }
      }

      &--loginText {
         color: white;
         margin-top: 2rem;
         cursor: pointer;
         margin-left: 2rem;

      }

      &--avatar {
         display: flex;
         width: 2.5rem;
         align-items: center;

         img {
            border-radius: 5px;
            border-radius: 50% !important;
            cursor: pointer;
            width: 30px;
            height: 30px;
         }

      }
   }

   &--2 {
      height: 10rem;
   }

   &-text {
      width: 60%;
      padding-top: 1.2rem;
      font-size: 1.2rem;

      &:hover {
         border-bottom: 1px solid #fff;
         // border-width: 2%;
      }
   }

   &-textOutside {
      font-size: 1.2rem;
      padding-bottom: 1rem;
   }

   &--user {
      height: 3rem;
      width: 3rem;
      border-radius: 5px;
      background-color: rgb(64, 168, 228);

      &-2 {
         background-color: rgb(230, 145, 48);
      }

      &-3 {
         background-color: rgb(123, 230, 96);
      }

      &-text {
         width: 3rem;
         font-size: 1.2rem;
         transform: translateX(4rem) translateY(-2rem);

         &:hover {
            border-bottom: 1px solid #fff;
            border-width: 100%;
         }
      }
   }

}