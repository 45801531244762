@import "../../../static/sass/config";

.profile-item {
   // &:not(:last-child) {
   //    margin-right: 2vw;
   // }

   &_icon, &_name{
      transition: transform ease-in-out 250ms, border ease-in-out 250ms, color ease-in-out 250ms;
      transition-delay: 100ms;
      transform: scale(1);
   }

   &_containerVisual:hover {
      .profile-item_icon, .profile-item_name{
         transform: scale(1.1);
      }
      .profile-item_name{
         color: whitesmoke;
      }
      .profile-item_icon::after {
         outline: calc(0.2vw + 1px) solid #e5e5e5;
      }
   }

   &_add{
      .profile-item_icon{
         background-size: 64% !important;
      }
   }

   &_icon {
      font-size: 14px;
      position: relative;
      border-radius: 5px;
      height: calc(8vw);
      width: calc(8vw);

      &::after {
         content: '';
         transition: outline ease-in-out 250ms ;
         transition-delay: 100ms;
         display: block;
         -webkit-border-radius: 100%;
         -moz-border-radius: 100%;
         border-radius: 100%;
         outline: 0px solid transparent;
         position: absolute;
         top: 0;
         right: 0;
         bottom: 0;
         left: 0;
      }
   }

   &_iconfallback {
      background: linear-gradient(
         to bottom,
         $color-brand-tint 60%,
         var(--orange) 100%
      );
   }

}