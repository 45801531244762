@import "../../static/sass/config";

.avatarList {
   display: grid;
   grid-template-columns: repeat(7, 90px);
   grid-template-rows: repeat(3, 90px);
   gap: 4em 3em;
   justify-content: center;

   &__items__1 {
      grid-template-columns: repeat(1, 90px) !important;
   }

   &__items__2 {
      grid-template-columns: repeat(2, 90px) !important;
   }

   &__items__3 {
      grid-template-columns: repeat(3, 90px) !important;
   }

   &__items__4 {
      grid-template-columns: repeat(4, 90px) !important;
   }

   &__items__5 {
      grid-template-columns: repeat(5, 90px) !important;
   }

   &__items__6 {
      grid-template-columns: repeat(6, 90px) !important;
   }

   .avatarList_avatar-item_icon {
      transition: transform ease-in-out 300ms, outline ease-in-out 300ms;
      transition-delay: outline 300ms;
      outline: solid 0px transparent;
      width: 100%;
      height: 100%;
      &:hover {
         transform: scale(1.2);
         outline: solid 5px white;
      }
   }

   @include responsive(tab_port) {
      grid-template-columns: repeat(4, 90px);
      grid-template-rows: repeat(4, 90px);
   }

   @include responsive(phone) {
      grid-template-columns: repeat(3, 90px);
      grid-template-rows: repeat(6, 90px);
   }
}
