@import "../../../static/sass/config";

$playerEpgSectionContainer-marginTop: $navbar-height;

$playerEpgSection-player-height: 50vh;

$playerEpgSection-epg-height: calc(50vh - #{$playerEpgSectionContainer-marginTop});
$playerEpgSection-epg-fullHeight: calc(100vh - #{$playerEpgSectionContainer-marginTop});

$playerEpgSection-epg-color-select: $background-color;

$playerEpgSection-epg-border: solid 1px #707070;

.playerEpgSectionContainer {
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
    margin-top: $playerEpgSectionContainer-marginTop;


    &_loading{
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .spinnerContainer{
        height: min-content;
        top: 16em;
        bottom: initial;
        margin: 0;
    }
    .playerEpgSectionPlayer{
        height: $playerEpgSection-player-height;
        .playerContainerVideojs{
            display: contents;
        }
        .playerEpgSectionPlayer_contentNotAvailable{
            display: flex;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: transparent;
            height: 100%;
            width: 100%;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            strong{
                color: white;
                font-size: 2rem;
            }
            div{
                color: white;
            }
        }
        .playerEpgSectionContainer_spineer_player{
            margin-top: $playerEpgSectionContainer-marginTop;
            .tbx-LoadingSpinner{
                height: 90px;
                width: 90px;
            }
        }
        .video-js, .playerEpgSectionContainer_spineer_player{
            height: $playerEpgSection-player-height;
        }
        .video-js .vjs-tech{
            background-color: $color-background;
        }

        .playerEpgSectionPlayerSpinner{
            height: $playerEpgSection-player-height;
            width: 100vw;
        }
        .playerExitButtomVideojs{
            display: none;
        }
    }
    .playerEpgSectionEpg{
        height: $playerEpgSection-epg-height;  
        &.playerEpgSectionEpg_fullHeight{
            height: $playerEpgSection-epg-fullHeight
        }
        .epgGridList{
            border: 0px !important;

            .epgGridList_swiper_broadcast_false {
                background-color: $color-primary !important;
                color: $color-white !important;
                cursor:  pointer !important;
                i::before{
                    color: $color-white !important;
                }
            }

            .epgGridList_swiper_broadcast_true {
                background-color: $bg-color-my-list-btn !important;
                color: $color-grey-typography!important;
                cursor:  none !important;
            
                i::before{
                    color: $color-grey-typography!important;
                }
            }


        }

        .epgGridList_swiper_broadcast{
            width: 4em;
            height: 4em;
            color: $color-white !important;
          
            i::before{
                color: $color-white;
            }
            
        }

        .epgGridList_swiper_lineTime{
            background-color: rgba($color-primary,0.8) !important;
            border: solid 1px  rgba(black,0.3);
        }
        
        .epgGridList .epgGridList_container_channel .epgGridList_row_channel_header {
            .epgGridList_row_channel_header_container{
                font-family: $fontClient_Medium;
                background: $background-component-color !important;
                border: solid 1px #FFFFFF1A !important;
                border-top: transparent !important;
                border-bottom: transparent !important;
            }
        }
        .epgGridList_row_header_toggle{
            background: $bg-epg-toggle-color !important;
            border: $playerEpgSection-epg-border!important;

            .epgGridList_row_header_description{
                font-family: $fontClient_Regular;
            }

            &_currentTime_true{
                background-color: $bg-epg-toggle-current-color !important;
            }

            &_selected_true{
                border-width: 3px !important;
                border-color: white !important;
                color: white;
            }
        }
        .epgGridList_row_itemBody{
            .epgGridList_row_itemBody_container {
                height: fit-content !important;
            }
            .epgGridList_row_itemBody_description{
                font-family: $fontClient_Regular;
                height: fit-content !important;
            }
            font-size: 1.125em;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            padding-left: 1rem;
        }
        .epgGridList_row_itemBody_button {
            color: $color-white !important;

            i::before{
                color: $color-white;
            }

            &:hover {
                background-color: $color-primary !important;
            }

            &.watch-again {
                background-color: $bg-color-my-list-btn !important;
                border: 1px solid #FFFFFF !important;
                border-radius: .5rem !important;
                font-family: $fontClient_Regular;

                &:hover {
                    background-color: $bg-color-my-list-btn !important;
                }
            }
        }

        .epgGridList_row_itemBody_current_true {
            background-color: $color-primary-dark;
        }
        .epgGridList_swiper_containerTime{
            font-family: $fontClient_Regular;
        }
        .epgGridList_swiper_containerTime{
            .epgGridList_swiper_time_container{
                background: $background-component-color !important;
                border: $playerEpgSection-epg-border !important;
                border-left: 0px !important;
                border-right: $playerEpgSection-epg-border  !important;
            }
        }
        .epgGridList_timeDropdown {
            .epgGridList_timeDropdown_button {
                .dropdown-toggle{
                    background: $background-component-color !important;
                    border: $playerEpgSection-epg-border !important;
                    border-left: 0px !important;
                }
                .dropdown-menu{
                    background: $color-background !important;
                    .dropdown-item{
                        background: $color-background !important;
                        &:hover{
                            color: $color-primary
                        }
                    }
                }      
            }
        }
        .epgGridList_row_collapse .epgGridList_row_collapse_body,.epgGridList .epgGridList_container_channel .epgGridList_row_channel_body{
            background: $background-component-color !important;
        }
    }
    .playerEpgSectionEpg_PictureInPicture{
            display:flex;
            position: absolute;
            margin-top: 5em;
            right: 2em;
            bottom: 8em;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width:4em;
            height:4em;
            border: none;
            border-radius: 50%;
            color:#f5f5f5;
            text-decoration:none;
            background: #555777;
            box-shadow: 0 0 0.25em gray;
            font-weight:bold;
            z-index: 99;
            transform: scale(1);
            transition: background-color ease-in-out 300ms, transform ease-in-out 300ms;
            &_true{
              background-color: $color-primary;
              transform: scale(1.05);
            }
            &_false{
              background-color: rgba($color-primary,0.7);
            }
            &:hover{
              transform: scale(1.05);
            }
            .playerEpgSectionEpg_PictureInPicture_icon{
              display: flex;
              align-items: center;
              font-size: 1.5em;
            }
            .playerEpgSectionEpg_PictureInPicture_text{
              font-size: 1rem;
            }
            transition: ease-in-out 200ms;
            &_true{
                bottom: initial;
            }
    }
    & ~ .footer {
        display: none;
    }
    
}

:picture-in-picture {
    box-shadow: 50px 50px 50px 50px red;
    margin: 50px;
}

.playerEpgSectionContainer.fade-exit.fade-exit-active,.playerEpgSectionContainer.fade-exit{
    display: none;
}