@import "../config";

$nav-item-selected-bg: $color-primary;
$nav-item-hover-bg: $color-primary-dark;
$nav-item-selected-text: $color-white;

body {
    font-size: 1em;
    background-color: $background-color  !important;
    font-family: $fontClient_Bold;
}

.btn {
    &:active,
    &:focus {
        box-shadow: none;
    }
}

.tbxContentCard {
    &__content-image {
        background-image: linear-gradient(to bottom, $color-gray 5%, $background-color 90%) !important;
        box-shadow: 8px 8px 4px rgba(83, 83, 83, 0.05) !important;
    }

    &__data-inner {
        align-items: flex-end;
        flex-direction: row;
        height: 77%;

        @include responsive(tab_medium) {
            height: 67%;
        }
    }
}

.navigation {
    font-family: $fontClient_Regular  !important;

    &__container {

        margin: 0 $base-size-6x;
        height: 100px;
        padding: 0;

        @include responsive(tab_medium) {
            margin: 0 $base-size-3x;
        }

        @include responsive(phone) {
            margin: 0 $base-size-2x;
        }

        // a:first-child {
        //     font-size: 0;
        //     padding-left: 0;
        // }

        &--downArrow {
            display: none;
        }

        &--bellLogo,
        .lang-select {
            display: none;
        }

        &--menuTrigger {

            display: none;

            @include responsive(tab_port) {
                display: block;
                margin-right: $base-size-2x;
            }

        }

        &--logo {

            @include responsive(tab_medium) {
                height: 5rem !important;
                padding-right: 0;
            }

        }

        &--left__input:focus {
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: $base-size-half;
        }

        &--searchLogo {
            margin: 0 $base-size 0 0;
            padding: $base-size 12px;
            height: $base-size-5x;
            width: $base-size-6x;
            transition: all 0.3s ease-in-out;

            img {
                height: $base-size-3x;
                width: $base-size-3x;
                padding: 0;
            }

            &:hover {
                background: $nav-item-selected-bg;
                border-radius: 3px;
            }

        }

        &--loginLogo {
            padding: $base-size 12px;
            background: $color-transparent;
            border-radius: 3px;
            margin-left: $base-size;
            height: $base-size-5x;
            transition: all 0.3s ease-in-out;

            img {
                width: $base-size-3x;
                height: auto;
            }

            span {
                font-weight: 600;
                text-transform: uppercase;
                font-size: 1rem;
                color: $color-white;
                margin-left: $base-size;

                @include responsive(tab_port) {
                    display: none;
                }

            }

            &:hover {
                background: $nav-item-selected-bg;
            }

        }


    }

    .logo {
        transform: translateX(3rem) translateY(1.1rem);
    }

    .pseudo-link.isImg_false {
        padding: $base-size-half 12px;
        margin: 0 $base-size-2x;
        font-weight: $font-regular;
        text-transform: uppercase;
        font-size: 1rem;
        color: $color-text-button;

        &:hover {
            background: $nav-item-selected-bg;
            color: $color-text-button-hover  !important;
            font-weight: 400;
            border-radius: 3px;
            text-decoration: none;
        }

        @include responsive(lg_desktop) {
            padding: $base-size-half 4px !important;
        }

    }

    .active-link.isImg_false  {
        background: $nav-item-selected-bg;
        color: $color-text-button  !important;
        font-weight: 400;
        border-radius: 3px;

        &:hover {
            background: $nav-item-hover-bg;
        }
    }

    &.black {
        box-shadow: 0 $base-size-half $base-size-2x rgba(18, 18, 18, 0.8);
    }

    .lang-select button {
        margin: 0 $base-size-half;
        border: none;
        background: $color-white-75;
        border-radius: 3px;
        text-transform: uppercase;
    }

    .dropdown-content {
        background-color: rgba(0, 0, 0, 1);
        border-radius: $base-size 0 $base-size $base-size;
        box-shadow: 0px 0px $base-size-2x $color-black-50;
        transform: translateY(3.8rem) translateX(-12rem);
        padding: 1rem 0;

        @include responsive(tab_port) {
            padding: 0;
        }

        &__container {

            &--userLogo {
                display: block;
                background: url(../../images/person-circle.svg) center center no-repeat;
                background-size: 28px 28px;
                margin: $base-size-2x;

                @include responsive(tab_port) {
                    margin: $base-size-2x 0;
                }
            }

        }

        &.dropdown-content {

            &--2 {
                height: auto;

                p,
                p:hover {
                    padding: 0.8rem 1.8rem;
                    margin: 0;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        color: $color-primary;
                        font-weight: 500;
                        background: $color-white-10;
                        transition: all 0.3s ease-in-out;
                    }

                }

            }

        }

    }

}


.searchSection__container .searchSection__results {

    @include responsive(tab_medium) {
        margin: 4rem 1rem !important;
    }

    @include responsive(phone) {
        margin: 1rem !important;
    }

    .infinite-scroll-component {
        overflow: visible !important;
    }

}

.searchSection {
    background-color: $color-transparent  !important;

    &__container {
        background-color: $color-transparent  !important;
    }

    .navigation {
        background: rgba(18, 18, 18, 1) !important;
    }

    &__inputGroup {
        padding: 100px 8% 32px;
        border: 0px solid $color-transparent  !important;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);

        @include responsive(tab_medium) {
            padding: 90px 4% 32px;
        }

        &-clearIcon {
            width: $base-size-3x  !important;
            height: $base-size-3x  !important;

            @include responsive(phone) {
                width: $base-size-2x  !important;
                height: $base-size-2x  !important;
            }

            &Disabled {
                width: $base-size-3x  !important;
                height: $base-size-3x  !important;

                @include responsive(phone) {
                    width: $base-size-2x  !important;
                    height: $base-size-2x  !important;
                }

            }

            &:hover {
                fill: $color-primary  !important;
                transform: scale(1.25) !important;
            }

        }


        &-append {
            border-bottom: 1px solid $color-primary;
        }

        .searchSection__searchBar {
            border-bottom: 1px solid $color-primary;
            border-radius: 0;
            font-weight: $font-medium;
            padding: 0 $base-size-4x;

            @include responsive(phone) {
                font-size: $text-size-l;
                padding: 0 $base-size-2x;
            }

            &.form-control {
                color: $color-white-50  !important;
            }

            &.form-control:focus {
                color: $color-white  !important;
                border-bottom: 1px solid $color-primary;
                box-shadow: none;
            }

            &.form-control::placeholder {
                color: $color-white-25  !important;
            }

        }

    }

    .tbxContentGrid__heading {
        font-family: $fontClient_Bold;
        text-transform: uppercase;
        font-weight: $font-light;
        color: $color-white;
        font-size: $text-size-xl;
        padding: 2.5rem 0 0.5rem;

        &:after {
            margin-top: $base-size;
            background-color: $color-primary;
        }

        @include responsive(tab_medium) {
            font-size: $text-size;
        }

    }

    .tbxContentGrid__gallery-THUMB {
        overflow: visible;
        padding-top: $base-size;
        grid-gap: $base-size-4x;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

        @include responsive(tab_medium) {
            padding-top: $base-size;
            grid-gap: $base-size-3x;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }

        @include responsive(phone) {
            padding-top: $base-size;
            grid-gap: $base-size-2x;
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            width: 100%;
        }

    }

    .tbxContentGrid__content {
        padding: 0;


        .tbxContentCard__data {
            font-weight: $font-regular  !important;
            padding: $base-size $base-size 0;

            &-title {
                font-family: $fontClient_Bold;
                font-size: 20px;
            }

            &-title,
            &-subtitle {

                @include responsive(tab_medium) {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-size: $text-size-s;
                }

            }
        }
    }

    .tbxContentCard__data {
        padding: $tbxContentListItemData-padding;
    }

    .tbxContentCard__content-image {
        border-radius: $base-size;
        box-shadow: $base-size $base-size $base-size-half rgba($color-primary, 0.05);
    }

}

/* CONTAINER WIDE */
.container-wide {
    display: block;
}


/* PLAYER */

.playerSectionContainer {
    background-color: $color-black;
}

.video-js {
    .vjs-chromecast-button {
        display: none !important;
    }

    .vjs-slider {
        background-color: $color-white-25;
        background-color: $color-white-25;
    }

    .vjs-play-progress,
    .vjs-volume-level {
        background-color: $color-primary;
    }

    .vjs-play-progress,
    .vjs-volume-level {
        background-color: $color-primary;
    }

    .vjs-load-progress {
        background: $color-white-25;
    }
    .title-overlay-container-span {
        font-size: $text-size-m;
        font-weight: $font-medium;
    }
}

/* BURGUER MENU */

.burgerMenuContainer {
    .bm-burger-button {
        background: rgba(0, 0, 0, 0.01) !important;
        width: 30px;
        height: 25px;
        outline: none;
    }

    .bm-burger-bars {
        background: #ffffff !important;
        height: 10% !important;
    }

    .bm-overlay {
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
    }

    .bm-menu-wrap {
        box-shadow: 8px 0 16px rgba(0, 0, 0, 0.65);
    }

    .bm-menu {
        background: $background-color;
        padding: 2.5em 0 0;
    }

    .bm-item-list {
        color: #ffffff;
        padding: 1em 0;
    }

    .bm-item {
        padding: 8px 0;
        outline: none;

        .nav-link {
            padding: 0.75rem 1.5rem;

            &.active {
                background: transparent;
                color: white  !important;
                background-color: $color-primary-dark !important;
            }

            &:hover {
                background-color: $color-primary-dark;
                color: $color-text-button-hover  !important;
            }

        }

    }

    .bm-cross {
        background: #777777;
        width: 3px !important;
    }

    .bm-cross-button:hover {
        span {
            transition: ease-in-out 300ms;
            background-color: $color-modal-grey;
        }
    }
}

/* ACCOUNT MENU */

.accountMenuContainer {

    .optionLinkContainer {
        height: initial;

        &:hover {
            background-color: $color-white-25;

            .textOperation {
                color: $color-text-button-hover;
            }

            .textOption {
                color: $color-text-button-hover;
            }
        }

    }

    .bm-cross-button {
        right: 1em !important;
        top: 1em !important;

        &:hover {
            span {
                transition: background-color ease-in-out 500ms;
                background-color: $color-modal-grey  !important;
            }
        }
    }

    .bm-burger-button {
        background: rgba(0, 0, 0, 0.01) !important;
        width: 30px;
        height: 25px;
        outline: none;
    }

    .bm-burger-bars {
        background: #ffffff !important;
        height: 10% !important;
    }

    .bm-overlay {
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
    }

    .bm-menu-wrap {
        box-shadow: 8px 0 16px rgba(0, 0, 0, 0.65);
        min-width: calc(fit-content + 2rem) !important;
    }

    .bm-menu {
        background: $background-color;
        padding: 2.5em 0 0;
    }

    .bm-item-list {
        color: #ffffff;
        padding: 1em 0;
    }

    .bm-item {
        padding: 8px 0;
        outline: none;
        .nav-link {
            padding-left: 1.5rem;
            overflow: hidden;

            &.active {
                background: $color-primary-dark !important;
                color: $color-text-button-hover;
            }
        }

    }

    .bm-cross {
        background: #777777;
        width: 3px !important;
    }

    .profileLinkContainer {
        &:hover {
            transition: ease-in-out 100ms;
            background-color: $color-gray;
            color: $color-text-button;
        }
    }
}

// PROFILES EDIT
.profile {
    &-option {
        &_list {
            @include responsive(phone) {
                align-items: stretch;
            }
        }
    }

    &-item {
        @include responsive(phone) {
            max-width: 30%;
            word-break: break-word;
            width: 30%;
        }
    }
}
