@import "../../sass/config";



.video-js {
  $tbx-player-main-color: $color-primary;
  &,
  * {
    font-family: $fontClient_Regular;
  }

  $player-icon-color-primary: white;
  $player-icon-color-text: black;
  $player-icon-color-primary-hover: $tbx-player-main-color;

  height: 100%;
  width: 100%;
  font-size: 1.2em;
  overflow: hidden;
  outline: none;

  *,
  &:after,
  &:before {
    box-sizing: inherit;
    font-size: inherit;
    color: inherit;
    line-height: inherit
  }

  .vjs-poster {
    display: none !important;
  }

  .title-overlay-container {
    visibility: hidden !important;
    opacity: 0 !important;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5998774509803921) 0%, rgba(0, 0, 0, 0.19931722689075626) 36%, rgba(0, 0, 0, 0.10407913165266103) 74%, rgba(0, 0, 0, 0.5018382352941176) 100%);
    transition: visibility 500ms ease-in-out, opacity 500ms ease-in-out !important;

    .title-overlay-container-div {
      visibility: hidden !important;
      opacity: 0 !important;
      transition: visibility 500ms ease-in-out, opacity 500ms ease-in-out !important;
    }
  }

  &.vjs-user-active,
  &.vjs-paused {
    &.vjs-has-started {
      .title-overlay-container {
        visibility: visible !important;
        opacity: 1 !important;

        .title-overlay-container-div {
          visibility: visible !important;
          opacity: 1 !important;
        }
      }
    }
  }

  .vjs-next-episode-close-btn{
    .vjs-icon-cancel{
      line-height: 1.2em;
      font-size: 2em;
      &::before{
        content: 'x';
      }
    }
  }

  .vjs-icon-play-circle {
    &::before {
      content: "";
    }
  }

  &.tbx-changesrc-waiting .title-overlay-container-div {
    display: none !important;
  }

  .title-overlay-container-div {
    font-family: $fontClient_Regular;
    bottom: 8em;
    margin: 0 0.8em;
    font-size: 2rem;
    font-weight: 500;

    .title-overlay-container-title{
      font-size: 3.25rem;
      padding-bottom: 16px;
    }
    .title-overlay-episode-container span{
      font-size: 2.25rem;
    }
  }

  &.vjs-has-started .vjs-touch-overlay {
    left: 0 !important;
  }

  .vjs-touch-overlay .vjs-play-control {
    width: 30% !important;
  }

  .vjs-vtt-thumbnail-display {
    bottom: 1250% !important;
  }

  &.vjs-user-active,
  &.vjs-paused {
    .vjs-text-track-cue {
      position: sticky !important;
    }

    .vjs-text-track-display {
      bottom: 9em !important;
    }
  }

  &.vjs-layout-x-small.vjs-liveui .vjs-progress-control,
  &.vjs-layout-tiny .vjs-progress-control:not(.vjs-hidden) {
    display: block !important;
  }

  .vjs-menu-button-inline.vjs-slider-active,
  .vjs-menu-button-inline:focus,
  .vjs-menu-button-inline:hover,
  &.vjs-no-flex .vjs-menu-button-inline {
    width: 10em
  }

  .vjs-control,
  .vjs-time-control.vjs-time-divider {
    color: inherit;
    width: 4em;
    height: 4em;
    outline: none;
  }



  .vjs-play-control {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }

  .vjs-icon-placeholder:before {
    display: inline-block;
    content: "" !important;
    vertical-align: -.125em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1em;
  }

  /* volume-icon */

  .vjs-icon-volume-high:before,
  .vjs-mute-control .vjs-icon-placeholder:before {
    mask-image: url("data:image/svg+xml,"+'<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><g id="Group_726" data-name="Group 726"><g id="noun-audio-830720"><path id="Path_357" data-name="Path 357" class="cls-1" d="M46.59,33.83l-12.52,10a1.06,1.06,0,0,1-.71.23H26a1.2,1.2,0,0,0-1.18,1.19v9.45A1.18,1.18,0,0,0,26,55.91H33.3a1.3,1.3,0,0,1,.71.23l12.52,10A1.17,1.17,0,0,0,48.17,66a1.22,1.22,0,0,0,.25-.73V34.77a1.14,1.14,0,0,0-1.06-1.19,1.15,1.15,0,0,0-.78.24Z"/></g><path id="Path_366" data-name="Path 366" class="cls-1" d="M63.78,38.37A1.31,1.31,0,0,0,62,40.3h0A12.84,12.84,0,0,1,66.28,50a12.48,12.48,0,0,1-4.44,9.71,1.31,1.31,0,0,0,1.72,2A15,15,0,0,0,68.9,50a15.37,15.37,0,0,0-5.12-11.6Z"/><path id="Path_367" data-name="Path 367" class="cls-1" d="M57.65,42.2a1.31,1.31,0,0,0-1.13,2.37,6.22,6.22,0,0,1,3.28,5.48,6,6,0,0,1-3.25,5.37,1.32,1.32,0,0,0,1.06,2.41l0,0a8.6,8.6,0,0,0,4.79-7.76A8.86,8.86,0,0,0,57.65,42.2Z"/><path id="Path_368" data-name="Path 368" class="cls-1" d="M68,33.92a1.31,1.31,0,1,0-1.76,1.94,18.75,18.75,0,0,1,6.3,14.19,18.23,18.23,0,0,1-6.37,14.08,1.31,1.31,0,1,0,1.73,2h0a20.78,20.78,0,0,0,7.26-16A21.28,21.28,0,0,0,68,33.92Z"/></g></svg>');
    mask-size: auto;
    mask-repeat: no-repeat;
    background-color: $player-icon-color-primary;
  }

  .vjs-mute-control:hover .vjs-icon-placeholder:before {
    background-color: $player-icon-color-primary-hover;
  }

  .vjs-icon-volume-mute:before,
  .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before {
    mask-image: url("data:image/svg+xml,"+'<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><g id="Group_727" data-name="Group 727"><path id="Path_357" data-name="Path 357" class="cls-1" d="M46.59,33.83l-12.52,10a1.13,1.13,0,0,1-.71.24H26a1.18,1.18,0,0,0-1.18,1.18v9.45A1.18,1.18,0,0,0,26,55.91H33.3a1.3,1.3,0,0,1,.71.23l12.52,10A1.17,1.17,0,0,0,48.17,66a1.22,1.22,0,0,0,.25-.73V34.77a1.14,1.14,0,0,0-1.06-1.19,1.11,1.11,0,0,0-.78.25Z"/><path class="cls-1" d="M64.55,49.94l4.61-4.6,0-.05a1.31,1.31,0,0,0-1.9-1.81l-4.6,4.61L58,43.41a1.31,1.31,0,0,0-1.86,1.85l4.68,4.68L56.19,54.6h0a1.31,1.31,0,0,0,0,1.85h0a1.31,1.31,0,0,0,1.85,0l4.66-4.66,4.58,4.58,0,.05a1.31,1.31,0,1,0,1.81-1.9Z"/></g></svg>');
    mask-size: auto;
    mask-repeat: no-repeat;
    background-color: $player-icon-color-primary;
  }

  .vjs-mute-control {
    &:hover {

      &.vjs-vol-0,
      &.vjs-vol-2,
      &.vjs-vol-1 {
        .vjs-icon-placeholder:before {
          background-color: $player-icon-color-primary-hover;
        }
      }
    }
  }

  .vjs-icon-volume-mid:before,
  .vjs-mute-control.vjs-vol-2 .vjs-icon-placeholder:before {
    mask-image: url("data:image/svg+xml,"+'<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><g id="Group_726" data-name="Group 726"><g id="noun-audio-830720"><path id="Path_357" data-name="Path 357" class="cls-1" d="M46.59,33.83l-12.52,10a1.06,1.06,0,0,1-.71.23H26a1.2,1.2,0,0,0-1.18,1.19v9.45A1.18,1.18,0,0,0,26,55.91H33.3a1.3,1.3,0,0,1,.71.23l12.52,10A1.17,1.17,0,0,0,48.17,66a1.22,1.22,0,0,0,.25-.73V34.77a1.14,1.14,0,0,0-1.06-1.19,1.15,1.15,0,0,0-.78.24Z"/></g><path id="Path_366" data-name="Path 366" class="cls-1" d="M63.78,38.37A1.31,1.31,0,0,0,62,40.3h0A12.82,12.82,0,0,1,66.28,50a12.48,12.48,0,0,1-4.44,9.71,1.31,1.31,0,0,0,1.72,2A15,15,0,0,0,68.9,50a15.37,15.37,0,0,0-5.12-11.6Z"/><path id="Path_367" data-name="Path 367" class="cls-1" d="M57.65,42.2a1.31,1.31,0,0,0-1.13,2.37,6.22,6.22,0,0,1,3.28,5.48,6,6,0,0,1-3.25,5.37,1.32,1.32,0,0,0,1.06,2.41l0,0a8.6,8.6,0,0,0,4.79-7.76A8.86,8.86,0,0,0,57.65,42.2Z"/></g></svg>');
    mask-size: auto;
    mask-repeat: no-repeat;
    background-color: $player-icon-color-primary;
  }

  .vjs-icon-volume-low:before,
  .vjs-mute-control.vjs-vol-1 .vjs-icon-placeholder:before {
    mask-image: url("data:image/svg+xml,"+'<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><g id="Group_726" data-name="Group 726"><g id="noun-audio-830720"><path id="Path_357" data-name="Path 357" class="cls-1" d="M46.59,33.83l-12.52,10a1.06,1.06,0,0,1-.71.23H26a1.2,1.2,0,0,0-1.18,1.19v9.45A1.18,1.18,0,0,0,26,55.91H33.3a1.3,1.3,0,0,1,.71.23l12.52,10A1.17,1.17,0,0,0,48.17,66a1.22,1.22,0,0,0,.25-.73V34.77a1.14,1.14,0,0,0-1.06-1.19,1.15,1.15,0,0,0-.78.24Z"/></g><path id="Path_367" data-name="Path 367" class="cls-1" d="M57.65,42.2a1.31,1.31,0,0,0-1.13,2.37,6.22,6.22,0,0,1,3.28,5.48,6,6,0,0,1-3.25,5.37,1.32,1.32,0,0,0,1.06,2.41l0,0a8.6,8.6,0,0,0,4.79-7.76A8.86,8.86,0,0,0,57.65,42.2Z"/></g></svg>');
    mask-size: auto;
    mask-repeat: no-repeat;
    background-color: $player-icon-color-primary;
  }

  /* fullscreen-icon */

  .vjs-fullscreen-control {
    order: 10;
  }

  .vjs-icon-fullscreen-enter:before,
  .vjs-fullscreen-control .vjs-icon-placeholder:before {
    mask-image: url("data:image/svg+xml,"+'<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><g id="noun-full-screen-1155367"><path id="Path_353" data-name="Path 353" class="cls-1" d="M53.71,70.23H68A2.28,2.28,0,0,0,70.23,68h0V53.71H65.67v12h-12Z"/><path id="Path_354" data-name="Path 354" class="cls-1" d="M70.23,46.3V32.05A2.28,2.28,0,0,0,68,29.78H53.71v4.55h12v12Z"/><path id="Path_355" data-name="Path 355" class="cls-1" d="M34.33,34.33h12V29.77H32.06a2.28,2.28,0,0,0-2.28,2.28V46.29h4.55Z"/><path id="Path_356" data-name="Path 356" class="cls-1" d="M29.77,68a2.28,2.28,0,0,0,2.28,2.28H46.29V65.67h-12v-12H29.77Z"/></g></svg>');
    mask-size: auto;
    mask-repeat: no-repeat;
    background-color: $player-icon-color-primary;
  }

  .vjs-fullscreen-control:hover .vjs-icon-placeholder:before {
    background-color: $player-icon-color-primary-hover;
  }

  .vjs-icon-fullscreen-exit:before,
  &.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before {
    mask-image: url("data:image/svg+xml,"+'<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path class="cls-1" d="M39.08,39.08H29.24v4.67H41.43a2.34,2.34,0,0,0,2.32-2.35V29.77H39.08Z"/><path class="cls-1" d="M60.92,39.08V29.77H56.25V41.4a2.34,2.34,0,0,0,2.32,2.35H70.76V39.08Z"/><path class="cls-1" d="M41.43,56.26H29.24v4.66h9.84v9.31h4.67V58.6A2.33,2.33,0,0,0,41.43,56.26Z"/><path class="cls-1" d="M56.25,58.6V70.23h4.67V60.92h9.84V56.26H58.57A2.33,2.33,0,0,0,56.25,58.6Z"/></svg>');
    mask-size: auto;
    mask-repeat: no-repeat;
    background-color: $player-icon-color-primary;
    //background-color: $player-icon-color-primary-focus;
  }

  &.vjs-fullscreen .vjs-fullscreen-control:hover .vjs-icon-placeholder:before {
    background-color: $player-icon-color-primary-hover;
  }

  /* chromecast-icon */

  .vjs-chromecast-button .vjs-icon-placeholder::before {
    mask-image: url("data:image/svg+xml,"+'<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve"> <path id="Path_350" class="st0" d="M28.9,44.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c9.7,0,17.6,7.9,17.7,17.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C51.8,55,41.5,44.7,28.9,44.7z M28.9,54.3c-1.5,0.1-2.6,1.3-2.5,2.8c0.1,1.3,1.1,2.4,2.5,2.5c4.5,0,8.1,3.6,8.1,8.1c-0.1,1.5,1,2.7,2.5,2.8c1.5,0.1,2.7-1,2.8-2.5c0-0.1,0-0.2,0-0.3C42.3,60.2,36.3,54.2,28.9,54.3C28.9,54.2,28.9,54.2,28.9,54.3L28.9,54.3z M71.1,29.8H28.9c-1.5,0-2.6,1.2-2.6,2.6l0,0v6.1c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6V35h36.9v30H58c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6h13.1c1.5,0,2.6-1.2,2.6-2.6l0,0V32.4C73.8,31,72.6,29.8,71.1,29.8z"/><path class="st0" d="M31.5,67.6c0,1.5-1.1,2.7-2.6,2.7c-1.5,0-2.7-1.1-2.7-2.6c0-1.5,1.1-2.7,2.6-2.7c0,0,0,0,0,0C30.3,65,31.5,66.1,31.5,67.6z"/></svg>');
    mask-size: auto;
    mask-repeat: no-repeat;
    background-color: $player-icon-color-primary;
  }

  .vjs-chromecast-button.vjs-chromecast-casting-state .vjs-icon-placeholder::before{
    mask-image: url("data:image/svg+xml,"+'<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve"><path id="Path_350" class="st0" d="M28.9,44.7c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c9.7,0,17.6,7.9,17.7,17.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6C51.8,55,41.5,44.7,28.9,44.7z M28.9,54.3c-1.5,0.1-2.6,1.3-2.5,2.8c0.1,1.3,1.1,2.4,2.5,2.5c4.5,0,8.1,3.6,8.1,8.1c-0.1,1.5,1,2.7,2.5,2.8c1.5,0.1,2.7-1,2.8-2.5c0-0.1,0-0.2,0-0.3C42.3,60.2,36.3,54.2,28.9,54.3C28.9,54.2,28.9,54.2,28.9,54.3L28.9,54.3z M71.1,29.8H28.9c-1.5,0-2.6,1.2-2.6,2.6l0,0v6.1c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6V35h36.9v30H58c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6h13.1c1.5,0,2.6-1.2,2.6-2.6l0,0V32.4C73.8,31,72.6,29.8,71.1,29.8z"/><path class="st0" d="M35.9,39.3v2.1c10,1.5,17.8,9.4,19.3,19.4H64V39.3H35.9z"/><path class="st0" d="M31.5,67.6c0,1.5-1.1,2.7-2.6,2.7c-1.5,0-2.7-1.1-2.7-2.6c0-1.5,1.1-2.7,2.6-2.7c0,0,0,0,0,0C30.3,65,31.5,66.1,31.5,67.6z"/></svg>');
    mask-size: auto;
    mask-repeat: no-repeat;
    background-color: $player-icon-color-primary;
  }

  .vjs-chromecast-button:hover .vjs-icon-placeholder::before {
    background-color: $player-icon-color-primary-hover;
  }


  /* play-icon */

  .vjs-play-control .vjs-icon-placeholder:before {
    background-size: 1.5em !important;
  }

  .vjs-icon-play:before,
  .vjs-play-control.vjs-paused .vjs-icon-placeholder:before {
    mask-image: url("data:image/svg+xml,"+'<svg id="Layer_1" data-name="Layer 1" fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path class="cls-1" d="M69.06,49.16,32,24.84a1,1,0,0,0-1,0,1,1,0,0,0-.53.88c0,21.6,0,24.23,0,28.87,0,3.24,0,7.46,0,19.77a1,1,0,0,0,1,1,1,1,0,0,0,.55-.16l37-24.32a1,1,0,0,0,0-1.68Z"/></svg>');
    mask-size: auto;
    mask-repeat: no-repeat;
    background-color: $player-icon-color-primary;
  }

  .vjs-play-control.vjs-paused {

    &:hover {
      .vjs-icon-placeholder:before {
        background-color: $player-icon-color-primary-hover;
      }
    }
  }

  /* pause-icon */
  .vjs-icon-pause:before,
  .vjs-play-control.vjs-playing .vjs-icon-placeholder:before {
    mask-image: url("data:image/svg+xml,"+'<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><g id="Group_670" data-name="Group 670"><g id="Group_669" data-name="Group 669"><rect id="Rectangle_1733" data-name="Rectangle 1733" class="cls-1" x="33.04" y="24.68" width="9.34" height="50.65" rx="2"/><rect id="Rectangle_1874" data-name="Rectangle 1874" class="cls-1" x="57.62" y="24.68" width="9.34" height="50.65" rx="2"/></g></g></svg>');
    mask-size: auto;
    mask-repeat: no-repeat;
    background-color: $player-icon-color-primary;
  }

  .vjs-play-control.vjs-playing {

    &:hover {
      .vjs-icon-placeholder:before {
        background-color: $player-icon-color-primary-hover !important;
      }
    }
  }

  /* subtitles-icon */

  .vjs-icon-subtitles:before,
  .vjs-subs-caps-button .vjs-icon-placeholder:before,
  &.video-js:lang(en-GB) .vjs-subs-caps-button .vjs-icon-placeholder:before,
  &.video-js:lang(en-IE) .vjs-subs-caps-button .vjs-icon-placeholder:before,
  &.video-js:lang(en-AU) .vjs-subs-caps-button .vjs-icon-placeholder:before,
  &.video-js:lang(en-NZ) .vjs-subs-caps-button .vjs-icon-placeholder:before,
  .vjs-subtitles-button .vjs-icon-placeholder:before {
    mask-image: url("data:image/svg+xml,"+'<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><g id="Group_721" data-name="Group 721"><g id="Group_720" data-name="Group 720"><path id="Path_363" data-name="Path 363" class="cls-1" d="M70.84,26.39H29.15a6.67,6.67,0,0,0-6.67,6.67V66.94a6.67,6.67,0,0,0,6.67,6.67H70.84a6.68,6.68,0,0,0,6.68-6.67V33.06a6.68,6.68,0,0,0-6.68-6.67M29.42,55.93h0a2.15,2.15,0,0,1,2.15-2.14H39a2.15,2.15,0,0,1,0,4.29H31.57a2.17,2.17,0,0,1-2.15-2.15m19,8.07a2.14,2.14,0,0,1-2.14,2.14h-12a2.15,2.15,0,0,1,0-4.29h12A2.16,2.16,0,0,1,48.47,64Zm12,0a2.14,2.14,0,0,1-2.14,2.14h-4.5a2.15,2.15,0,0,1-.09-4.29h4.59A2.16,2.16,0,0,1,60.43,64Zm7.42,0a2.15,2.15,0,1,1-2.15-2.15A2.14,2.14,0,0,1,67.85,64Zm2.73-8.07a2.14,2.14,0,0,1-2.14,2.15H46.38a2.15,2.15,0,0,1,0-4.29H68.43A2.14,2.14,0,0,1,70.58,55.93Z"/></g></g></svg>');
    mask-size: auto;
    mask-repeat: no-repeat;
    background-color: $player-icon-color-primary;
  }

  .vjs-subs-caps-button:hover .vjs-icon-placeholder:before,
  &.video-js:lang(en-GB):hover .vjs-subs-caps-button .vjs-icon-placeholder:before,
  &.video-js:lang(en-IE):hover .vjs-subs-caps-button .vjs-icon-placeholder:before,
  &.video-js:lang(en-AU):hover .vjs-subs-caps-button .vjs-icon-placeholder:before,
  &.video-js:lang(en-NZ):hover .vjs-subs-caps-button .vjs-icon-placeholder:before,
  .vjs-subtitles-button:hover .vjs-icon-placeholder:before {
    background-color: $player-icon-color-primary-hover;
  }


  .vjs-subs-caps-button[aria-expanded="true"] .vjs-icon-placeholder:before,
  &.video-js:lang(en-GB) .vjs-subs-caps-button[aria-expanded="true"] .vjs-icon-placeholder:before,
  &.video-js:lang(en-IE) .vjs-subs-caps-button[aria-expanded="true"] .vjs-icon-placeholder:before,
  &.video-js:lang(en-AU) .vjs-subs-caps-button[aria-expanded="true"] .vjs-icon-placeholder:before,
  &.video-js:lang(en-NZ) .vjs-subs-caps-button [aria-expanded="true"].vjs-icon-placeholder:before,
  .vjs-subtitles-button[aria-expanded="true"] .vjs-icon-placeholder:before {
    //background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm3.027 4.002c-.83 0-1.319.642-1.319 1.753v.743c0 1.107.48 1.727 1.319 1.727.69 0 1.138-.435 1.186-1.05H7.36v.114c-.057 1.147-1.028 1.938-2.342 1.938-1.613 0-2.518-1.028-2.518-2.729v-.747C2.5 6.051 3.414 5 5.018 5c1.318 0 2.29.813 2.342 2v.11H6.213c-.048-.638-.505-1.108-1.186-1.108zm6.14 0c-.831 0-1.319.642-1.319 1.753v.743c0 1.107.48 1.727 1.318 1.727.69 0 1.139-.435 1.187-1.05H13.5v.114c-.057 1.147-1.028 1.938-2.342 1.938-1.613 0-2.518-1.028-2.518-2.729v-.747c0-1.7.914-2.751 2.518-2.751 1.318 0 2.29.813 2.342 2v.11h-1.147c-.048-.638-.505-1.108-1.187-1.108z' clip-rule='evenodd'/></svg>");
  }

  /* audio-icon */

  .vjs-icon-audio:before,
  .vjs-audio-button .vjs-icon-placeholder:before {
    mask-image: url("data:image/svg+xml,"+'<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve"> <path class="st0" d="M70.8,26.4H29.2c-3.7,0-6.7,3-6.7,6.7v33.9c0,3.7,3,6.7,6.7,6.7h41.7c3.7,0,6.7-3,6.7-6.7V33.1C77.5,29.4,74.5,26.4,70.8,26.4z M48.9,61.1c0,0.5-0.3,0.8-0.8,0.8c-0.2,0-0.4-0.1-0.5-0.2l-8.8-7c-0.1-0.1-0.3-0.2-0.5-0.2h-5.1c-0.5,0-0.8-0.4-0.8-0.8v-6.6c0-0.5,0.4-0.8,0.8-0.8h5.1c0.2,0,0.4-0.1,0.5-0.2l8.8-7l0,0C47.8,39,48,39,48.2,39c0.4,0,0.8,0.4,0.7,0.8L48.9,61.1z M56.5,53.3c-0.1,0.6-0.6,1.1-1.2,1c-0.5-0.1-0.9-0.5-1-1v-6.6c0.1-0.6,0.6-1.1,1.2-1c0.5,0.1,0.9,0.5,1,1V53.3z M62.1,59.7c0.1,0.6-0.4,1.2-1,1.2s-1.2-0.4-1.2-1c0-0.1,0-0.1,0-0.2V40.3c-0.1-0.6,0.4-1.2,1-1.2s1.2,0.4,1.2,1c0,0.1,0,0.1,0,0.2V59.7z M67.6,53.3c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1v-6.6c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1V53.3z"/></svg>');
    mask-size: auto;
    mask-repeat: no-repeat;
    background-color: $player-icon-color-primary;
  }

  .vjs-audio-button:hover .vjs-icon-placeholder:before {
    background-color: $player-icon-color-primary-hover;
  }

  .vjs-audio-button[aria-expanded="true"] .vjs-icon-placeholder:before {
    //background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path d='M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z' style='fill: white;'/>  <rect x='0.582' y='2.765' width='14.483' height='10.313' style='fill: white;'/>  <path d='M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z'/></svg>");
  }

  /* big-play-icon */

  .vjs-big-play-button .vjs-icon-placeholder:before {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-play-circle' viewBox='0 0 16 16'><path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/><path d='M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z'/></svg>")
  }

  .vjs-picture-in-picture-control {
    order: 9;

    &:disabled{
      display: none;
    }

    .vjs-icon-placeholder {
      &::before {
        mask-image: url("data:image/svg+xml,"+'<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><g id="Group_717" data-name="Group 717"><path id="Path_351" data-name="Path 351" class="cls-1" d="M70.53,70.16h-41a5.1,5.1,0,0,1-5.14-5V35a5.13,5.13,0,0,1,5.12-5.13H70.52A5.14,5.14,0,0,1,75.66,35V65.21A5.1,5.1,0,0,1,70.53,70.16Zm-41.06-37a1.64,1.64,0,0,0-1.64,1.64h0V65a1.64,1.64,0,0,0,1.63,1.65H70.52A1.64,1.64,0,0,0,72.17,65h0V35a1.64,1.64,0,0,0-1.63-1.65H29.47Z"/><path id="Path_352" data-name="Path 352" class="cls-1" d="M50.92,48.17H67.41a.86.86,0,0,1,.92.81V61.91a.88.88,0,0,1-.82.92H50.92A.87.87,0,0,1,50,62V49.08a.87.87,0,0,1,.81-.91A.21.21,0,0,1,50.92,48.17Z"/></g></svg>');
        mask-size: auto;
        mask-repeat: no-repeat;
        background-color: $player-icon-color-primary;
      }
    }

    &[title='Exit Picture-in-Picture'] .vjs-icon-placeholder {
      &::before {
        //background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-play-circle' viewBox='0 0 16 16'><path d='M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm7 6h5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5z'/></svg>");
      }
    }

    &:hover .vjs-icon-placeholder::before {
      background-color: $player-icon-color-primary-hover;
    }
  }

  .vjs-audio-button {
    right: 0;
  }

  .vjs-audio-button .vjs-menu-content::before,
  .vjs-subs-caps-button .vjs-menu-content::before,
  .vjs-descriptions-button .vjs-menu-content::before {
    content: '';
    margin: 24px;
    display: block;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .vjs-audio-button .vjs-menu-content::before {
    content: var(--audio, 'Audio');
  }

  /*.vjs-audio-button .vjs-menu-content::after,
  .vjs-subs-caps-button .vjs-menu-content::after,
  .vjs-descriptions-button .vjs-menu-content::after {
    content: ' ';
    display: block;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-x' viewBox='0 0 16 16'> <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/></svg>");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 32px;
    height: 32px;
    width: 32px;
    position: absolute;
    right: 16px;
    top: 16px;
    transform: scale(1);
  }*/

  .vjs-audio-button .vjs-menu-content::after:hover {
    transform: scale(1.5);
    transition: transform 100ms ease-in-out;
  }

  .vjs-subs-caps-button .vjs-menu-content::before,
  .vjs-descriptions-button .vjs-menu-content::before {
    content: var(--subtitles, 'Subtítulos');
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected::before {
    content: '';
    -webkit-mask: url("data:image/svg+xml; utf8,"+'<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path id="check_white" data-name="check white" class="cls-1" d="M63.11,37.42a2.5,2.5,0,0,0-1.73.81c-6.15,6.49-10.81,11.88-16.6,18.07l-6.36-5.66A2.46,2.46,0,0,0,36.59,50a2.5,2.5,0,0,0-1.71.92,2.75,2.75,0,0,0,.28,3.73l8.17,7.27a2.42,2.42,0,0,0,3.41-.14C53.49,54.68,58.31,49,65,42h0a2.74,2.74,0,0,0,0-3.81A2.44,2.44,0,0,0,63.11,37.42Z"/></svg>');
    mask: url("data:image/svg+xml; utf8,"+'<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path id="check_white" data-name="check white" class="cls-1" d="M63.11,37.42a2.5,2.5,0,0,0-1.73.81c-6.15,6.49-10.81,11.88-16.6,18.07l-6.36-5.66A2.46,2.46,0,0,0,36.59,50a2.5,2.5,0,0,0-1.71.92,2.75,2.75,0,0,0,.28,3.73l8.17,7.27a2.42,2.42,0,0,0,3.41-.14C53.49,54.68,58.31,49,65,42h0a2.74,2.74,0,0,0,0-3.81A2.44,2.44,0,0,0,63.11,37.42Z"/></svg>');
    mask-size: auto;
    mask-repeat: no-repeat;
    width: 60px;
    height: 60px;
    background-color: $player-icon-color-text;
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-item:before {
    content: ' ';
    width: 8px;
    margin-right: 50px;
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected {
    &:before {
      margin-right: 0px;
    }
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-item:hover,
  .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected {
    font-weight: 600;
    background-color: $player-icon-color-primary-hover;
    color: $player-icon-color-text;
    display: flex;
    align-items: center;

  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-item {
    margin-left: 0.5em;
  }


  .vjs-menu-button-inline:before {
    width: 1.5em
  }

  .vjs-menu-button-inline .vjs-menu {
    left: 3em
  }

  .vjs-mouse-display .vjs-time-tooltip {
    background-color: $player-icon-color-primary;
    color: #000;
    font-size: .7em;
    padding: 12px;
  }

  .vjs-progress-control .vjs-play-progress::after {
    content: "";
    position: absolute;
    left: auto;
    top: 0;
    bottom: 0;
    right: -0.5em;
    margin: auto;
    width: 1em;
    height: 1em;
    background: #ffffff;
    -moz-border-radius: 3.125em;
    -webkit-border-radius: 3.125em;
  }

  .vjs-progress-control:hover .vjs-mouse-display {
    background-color: transparent;
  }

  .vjs-mouse-display:after,
  .vjs-play-progress:after {
    padding: 0 .4em .3em
  }

  .vjs-volume-control .vjs-mouse-display {
    background-color: #b3b3b3;
  }

  &.vjs-fullscreen,
  &.vjs-fullscreen .vjs-tech {
    width: 100% !important;
    height: 100% !important;
    outline: none;
  }

  .vjs-menu-button-inline:hover,
  &.vjs-no-flex .vjs-menu-button-inline {
    width: 8.35em
  }

  .vjs-volume-menu-button.vjs-volume-menu-button-horizontal:hover .vjs-menu .vjs-menu-content {
    height: 3em;
    width: 6.35em
  }

  .vjs-mute-control {
    height: 100%;
  }

  input {
    outline: none;
  }

  .vjs-spacer,
  .vjs-time-control {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex: 1 1 auto;
    -moz-box-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
  }

  .vjs-time-control {
    -webkit-box-flex: 0 1 auto;
    -moz-box-flex: 0 1 auto;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: auto;
    padding: 0;
    display: flex;
    align-items: center;
    min-width: 0;
    font-size: 1em;
    color: #ffffffe0;
    position: absolute;
    margin: 0 2.5em;
    height: 3em;
    margin-bottom: 1.78em;
    padding: 0 0.78em;

    &.vjs-duration,
    &.vjs-current-time {
      bottom: 0;
    }

    &.vjs-duration {
      right: 0;
    }

    &.vjs-current-time {
      left: 0;
    }
  }

  .vjs-volume-bar.vjs-slider-horizontal {
    border-radius: 5px;
  }

  .vjs-time-control.vjs-time-divider div {
    text-align: center
  }

  .vjs-time-control .vjs-current-time-display,
  .vjs-time-control .vjs-duration-display {
    width: 100%
  }

  .vjs-time-control .vjs-current-time-display {
    text-align: right;
    padding: 0;
    font-weight: 600;
  }

  .vjs-time-control .vjs-duration-display {
    text-align: left;
    font-weight: 600;
  }

  .vjs-progress-control .vjs-play-progress:before,
  .vjs-play-progress:before,
  .vjs-remaining-time,
  &.tbx-live .vjs-time-control.vjs-current-time,
  &.tbx-live .vjs-time-control.vjs-duration,
  &.tbx-live .vjs-time-control.vjs-time-divider,
  &.vjs-no-flex .vjs-time-control.vjs-remaining-time {
    display: none
  }

  /*.vjs-volume-control {
    width: 6em;
    height: 3em;
    margin-right: 0;
  }*/
  .vjs-volume-panel.vjs-hover .vjs-mute-control~.vjs-volume-control.vjs-volume-horizontal,
  .vjs-volume-panel.vjs-hover .vjs-volume-control.vjs-volume-horizontal {
    width: 16em;
    height: 3em;

    .vjs-volume-bar.vjs-slider-horizontal {
      width: 6em;
    }
  }

  .vjs-volume-panel .vjs-volume-control {
    width: 1em;
    height: 3em;
  }

  &.vjs-user-inactive .vjs-volume-control .vjs-mouse-display {
    opacity: 1;
  }

  &.vjs-no-flex .vjs-time-control {
    display: table-cell;
    width: 4em
  }

  .vjs-progress-control {
    position: absolute;
    height: 0.5em;
    width: auto;
    top: unset;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 3em 7em;
    margin-top: auto;
    padding: 0 0.5em;
  }


  .vjs-progress-control .vjs-load-progress,
  .vjs-progress-control .vjs-play-progress,
  .vjs-progress-control .vjs-progress-holder,
  .vjs-load-progress div {
    height: 100%;
    border-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .vjs-progress-control .vjs-progress-holder {
    margin: 0;
  }

  .vjs-control-bar {
    z-index: 1;
    background-color: transparent;
    border-radius: 0.313em 0.313em 0 0;
    height: 10.125em;
    color: #ffffff;
    font-size: 1em;
    padding: 0 2em;
    opacity: 1;
    -webkit-transform: translateY(0em);
    -moz-transform: translateY(0em);
    -ms-transform: translateY(0em);
    -o-transform: translateY(0em);
    transform: translateY(0em);
    -webkit-transition: -webkit-transform 0.2s ease 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 0.2s ease 0s, opacity 0.1s ease 0s;
    -ms-transition: -ms-transform 0.2s ease 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 0.2s ease 0s, opacity 0.1s ease 0s;
    transition: transform 0.2s ease 0s, opacity 0.1s ease 0s;

    .vjs-progress-control:hover {
      top: unset;
    }

    *:focus-visible {
      text-shadow: none !important;
      box-shadow: none !important;
      border: transparent;
      outline: solid 1px rgb(255, 255, 255);
      border-radius: 0.5rem;
    }

    * {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }

    .vjs-volume-panel {
      display: flex;
      align-items: center;
    }
  }

  &.tbx-changesrc-waiting .vjs-control-bar,
  &.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-control-bar,
  &.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-control-bar,
  &.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar,
  &.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar,
  &.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar {
    visibility: visible;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateY(6.25em);
    -moz-transform: translateY(6.25em);
    -ms-transform: translateY(6.25em);
    -o-transform: translateY(6.25em);
    transform: translateY(6.25em);
    -webkit-transition: -webkit-transform 1s ease 0s, opacity 0.8s ease 0s;
    -moz-transition: -moz-transform 1s ease 0s, opacity 0.8s ease 0s;
    -ms-transition: -ms-transform 1s ease 0s, opacity 0.8s ease 0s;
    -o-transition: -o-transform 1s ease 0s, opacity 0.8s ease 0s;
    transition: transform 1s ease 0s, opacity 0.8s ease 0s;
  }

  &.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-progress-control,
  &.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-progress-control,
  &.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-progress-control,
  &.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control,
  &.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control {
    height: .25em;
    top: unset;
    pointer-events: none;
    -webkit-transition: height 1s, top 1s;
    -moz-transition: height 1s, top 1s;
    -ms-transition: height 1s, top 1s;
    -o-transition: height 1s, top 1s;
    transition: height 1s, top 1s
  }

  &.not-hover.vjs-has-started.vjs-paused.vjs-user-active.vjs-fullscreen .vjs-progress-control,
  &.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive.vjs-fullscreen .vjs-progress-control,
  &.not-hover.vjs-has-started.vjs-playing.vjs-user-active.vjs-fullscreen .vjs-progress-control,
  &.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control,
  &.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control {
    opacity: 0;
    -webkit-transition: opacity 1s ease 1s;
    -moz-transition: opacity 1s ease 1s;
    -ms-transition: opacity 1s ease 1s;
    -o-transition: opacity 1s ease 1s;
    transition: opacity 1s ease 1s
  }

  .vjs-big-play-button {
    top: 50%;
    left: 50%;
    margin-left: -0.96em;
    margin-top: -1em;
    width: 2em;
    height: 2em;
    line-height: 2em;
    border: none;
    border-radius: 50%;
    font-size: 3.5em;
    background-color: rgba(0, 0, 0, .45);
    color: #fff;
    -webkit-transition: border-color .4s, outline .4s, background-color .4s;
    -moz-transition: border-color .4s, outline .4s, background-color .4s;
    -ms-transition: border-color .4s, outline .4s, background-color .4s;
    -o-transition: border-color .4s, outline .4s, background-color .4s;
    transition: border-color .4s, outline .4s, background-color .4s
  }

  &.vjs-seeking .vjs-big-play-button,
  &.vjs-waiting .vjs-big-play-button,
  .tbx-changesrc .vjs-big-play-button {
    display: none;
  }

  .vjs-menu-button-popup .vjs-menu {
    left: 0;
    width: 100%;
    margin-bottom: 2em;
  }

  div.vjs-menu-button {
    margin: 0 0.25em;
  }

  .vjs-volume-panel.vjs-control.vjs-volume-panel-horizontal,
  button.vjs-fullscreen-control.vjs-control.vjs-button {
    margin-left: 0.25em;
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    background-color: transparent;
    border-radius: 16px;
    width: 18em;
    bottom: 1em;
    padding-bottom: .5em;
    max-height: 35em;
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-content::-webkit-scrollbar {
    display: none;
  }

  .vjs-audio-button .vjs-menu .vjs-menu-content {
    left: -10em;
  }

  .vjs-subs-caps-button .vjs-menu .vjs-menu-content {
    left: -6.5em;
  }

  @keyframes entrada {
    from {
      opacity: 0;
      transform: scale(0);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }


  .vjs-menu.vjs-lock-showing .vjs-menu-content {
    animation: entrada 200ms ease-in-out;
  }

  .ima-ad-container {
    transition: margin-top 0.5s;
  }

  .vjs-menu-button-popup.vjs-hover .vjs-menu:not(.vjs-lock-showing){
    display: none !important;
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-item,
  .vjs-menu-button-popup .vjs-menu .vjs-menu-title {
    background-color: #151b17;
    margin: .3em 0;
    padding: .5em;
    border-radius: .3em;
    height: 60px;
    span{
      height: 60px;
      line-height: 60px;
    }
  }

  .vjs-menu li.vjs-selected,
  .vjs-menu li.vjs-selected:focus,
  .vjs-menu li.vjs-selected:hover,
  .js-focus-visible .vjs-menu li.vjs-selected:hover {
    color: white;
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-item,
  .vjs-menu-button-popup .vjs-menu .vjs-menu-title {
    background-color: transparent;
    display: flex;
    padding: 0;
    text-transform: capitalize;
    text-align: left;
  }

  .vjs-big-play-button {
    background-color: rgba(0, 0, 0, 0);
    font-size: 8em;
    border-radius: 0%;
    height: 1em !important;
    line-height: 1em !important;
    margin-top: -0.8em !important
  }

  &:hover .vjs-big-play-button,
  .vjs-big-play-button:focus,
  .vjs-big-play-button:active {
    background-color: rgba(0, 0, 0, 0)
  }


  &.vjs-seeking .vjs-loading-spinner,
  &.vjs-waiting .vjs-loading-spinner,
  &.tbx-changesrc-waiting .vjs-loading-spinner {
    display: block;
    visibility: visible;
    z-index: 9;
    position: absolute;
    top: 50%;
    left: 50%;
    border: transparent;
    margin-top: -90px;
    margin-left: -40px;
    @include spinner($color-primary , $color-secondary, 90px);
  }

  .tbx-changesrc-waiting .vjs-big-play-button {
    display: none;
  }


  .vjs-waiting .title-overlay-container,
  .vjs-seeking .title-overlay-container {
    background-color: rgba(0, 0, 0, .4196078431372549);
  }

  .title-overlay-container .title-overlay-container-span {
    padding-bottom: 0.5em;
  }

  .vjs-next-episode-container {
    width: -webkit-fill-available;
    bottom: 6.5em;
    left: 0;
    margin: 0 2em;
    background-color: #212121;
    transition: bottom 300ms ease-in-out;
    -webkit-transition: bottom 300ms ease-in-out;
    -moz-transition: bottom 300ms ease-in-out;
    -ms-transition: bottom 300ms ease-in-out;
    -o-transition: bottom 300ms ease-in-out;
  }

  &.vjs-user-inactive:not(.vjs-paused) {
    .vjs-next-episode-container {
      bottom: 2em;
    }
  }

  .vjs-play-progress,
  .vjs-volume-level {
    background-color: $player-icon-color-primary-hover;
    border-radius: 5px;
    height: 100%;
  }

  .vjs-load-progress {
    background: rgba(179, 179, 179, 0.8);
  }

  .vjs-big-play-button:hover {
    color: $player-icon-color-primary-hover;
  }


  .vjs-control:focus:before,
  .vjs-control:hover:before {
    color: $player-icon-color-primary-hover;
    text-shadow: none
  }

  .vjs-custom-control-spacer {
    height: 2.188em;
  }

  .vjs-volume-level::before {
    content: ' ';
    display: block;
    background-color: #ffffff;
    height: 12px;
    width: 12px;
    right: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
  }

  .vjs-play-progress.vjs-slider-bar .vjs-time-tooltip {
    display: none !important;
  }


  .vjs-current-time:not(.vjs-hidden),
  .vjs-time-divider:not(.vjs-hidden),
  .vjs-duration:not(.vjs-hidden) {
    display: block !important;
    order: -1;
  }

  /*-----------tbx-live--------------*/

  &.tbx-live {

    .vjs-live-control {
      margin-left: 1em
    }

    &:not(.tbx-hascatchup) {

      .vjs-current-time:not(.vjs-hidden),
      .vjs-time-divider:not(.vjs-hidden),
      .vjs-duration:not(.vjs-hidden) {
        display: none !important;
      }

      .vjs-progress-control {
        margin: 3em 2.5em;
      }
    }

    &:not(.tbx-restart) {
      .vjs-progress-control.vjs-control {
        display: none !important;
      }

      .vjs-control-bar {
        height: 4em;
      }

      .title-overlay-container-div {
        bottom: 4em;
      }

      .vjs-control,
      .vjs-time-control.vjs-time-divider {
        width: 3.5em;
        height: 3.5em;
      }
    }

    .vjs-current-time-display-seeking {
      &::before {
        content: "";
        background-color: $player-icon-color-primary;
        height: 14px;
        width: 14px;
        margin-right: 0.4em;
        border-radius: 50%;
      }
    }



    &:not(.tbx-restart).tbx-hascatchup .vjs-current-time-display {
      display: none;
    }

    &.tbx-restart .vjs-current-time-display {
      height: 2.5em !important;
    }

    &:not(.tbx-hascatchup) .vjs-current-time-display {
      order: -1;
      color: white;
      font-weight: 600;
      background-color: #FF0000;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0.5em;
      margin: 0.7em 1em;
      font-size: 1em;
      padding-left: 0.5em;
      padding-right: 0.5em;

      &:not(.vjs-current-time-display-seeking) {
        background-color: transparent;
        border: solid #FF0000 3px;
      }
    }
  }

  .vjs-time-control.vjs-time-divider {
    width: 0.125em;
    padding: 0 0.125em;
    display: none !important;
  }

  /*-----------tbx-full-window--------------*/


  &.tbx-full-window {
    display: contents !important;

    &.vjs-layout-tiny.vjs-remaining-timeayout-tiny:not(.vjs-hidden),
    &.vjs-layout-tiny .vjs-captions-button:not(.vjs-hidden),
    &.vjs-layout-tiny .vjs-subtitles-button:not(.vjs-hidden),
    &.vjs-layout-tiny .vjs-subs-caps-button:not(.vjs-hidden),
    &.vjs-layout-tiny .vjs-audio-button:not(.vjs-hidden),
    &.vjs-layout-tiny .vjs-volume-control:not(.vjs-hidden) {
      display: block !important;
    }

    .vjs-control-bar {
      position: fixed;
    }

    .vjs-tech {
      background-color: #000000;
    }

    .vjs-time-tooltip,
    .vjs-mouse-display .vjs-volume-tooltip {
      right: -30px !important;
      top: -2.9em;
      color: #000;
      background-color: hsla(0, 0%, 100%, .99);
    }

    .vjs-vtt-thumbnail-display {
      right: -85px !important;
    }

    &.vjs-paused .bumpable-ima-ad-container,
    &.vjs-playing:hover .bumpable-ima-ad-container,
    &.vjs-user-active.vjs-playing .bumpable-ima-ad-container {
      margin-top: -5.5em !important;
    }
  }

  /*-----------------------------------------*/


  @media only screen and (max-width: 1080px) {
    &.tbx-full-window .vjs-big-play-button .vjs-icon-placeholder:before {
      background-size: 0.6em;
    }
  }

  @media only screen and (max-width: 37.5em) {
    font-size: 1em;

    .vjs-volume-panel {
      flex-direction: column-reverse;
      align-items: flex-start !important;
    }


    .vjs-time-control.vjs-time-divider,
    .vjs-duration,
    .vjs-no-flex .vjs-duration {
      display: none !important;
    }

    &.tbx-full-window.vjs-layout-tiny .vjs-current-time,
    &.tbx-full-window.vjs-layout-tiny .vjs-time-divider,
    &.tbx-full-window.vjs-layout-tiny .vjs-duration {
      display: none !important;
    }
  }
}