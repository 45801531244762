@import "../config";
/*OVERRIDE RELATED CONTENT DEFAULT STYLES*/

.contentRelatedWrapped{
    background: $color-transparent;
    padding: 0 $base-size-6x $base-size-9x;

    @include responsive(tab_medium){
        padding: 0 $base-size-3x;
    }

    @include responsive(phone){
        padding: 0 $base-size-2x;
    }

}

.contentRelatedHead{
    margin-left: 0 !important;
}

.contentRelatedTitle{
    font-family: $fontClient_Regular;
    font-weight: 600!important;
    font-size: 25px !important;

    &:after{
        margin-top: $base-size !important;
    }

    @include responsive(phone){
        font-size: $text-size !important;
    }

}

.contentRelatedList{
    grid-gap: $base-size-4x !important;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
    grid-auto-rows: minmax(250px, auto) !important;

    @include responsive(tab_medium){
        grid-gap: $base-size-3x !important;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
        grid-auto-rows: minmax(200px, auto) !important;
    }

    @include responsive(phone){
        grid-gap: $base-size-2x !important;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)) !important;
        grid-auto-rows: minmax(120px, auto) !important;
    }

}

.contentRelatedPoster{
    justify-content: start !important;

    .nav-link{
        padding: 0;
        width: 100%;

        .tbxContentCard__content-image {
            width: 100%;
            max-width: 100%;
            box-shadow: $base-size $base-size $base-size-2x $color-black-50 !important;
        }
    }

}

$contentRelatedPoster-noImageWidth: 250px;
$contentRelatedPoster-noImageHeight: 375px;

/*NO IMAGE SIZE*/

.noImageResize {
    width: 100% !important;
    height: 100% !important;
  }
  
  
