@import "../../static/sass/config";

.activateCodeContainer {

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    row-gap: 2rem;
    margin-top: 5rem;
    padding: 3rem;
    margin-bottom: 150px;

    swd-pin-field{
        margin: 0 16px;
        max-width: 500px;
        .activateCode{
            height: 60px;
            width: 60px;
        }
    }

    label {
        color: white;
    }

    input {
        text-transform: uppercase;
    }

    .activateCodeCheckCircle{
        font-size: 75px;
        text-align: center;
        margin-top: 100px;
    }

    .activateCodeSuccess{
        font-family: $fontClient_Bold;
        text-align: center;
        font-size: 25px;
        width: 206px;
        height: 66px;
        color: $color-white;
        margin-bottom: 100px;
    }

    .activateCodeAlert{
        display: flex;
        align-items: center;
        min-height: 80px;

        .alert{
            width: 100%;
            margin: 0 16px;
            max-width: 500px;
            p{
                margin-bottom: 0px;
                text-align: center;
            }
        }
        

    }

    @include responsive(tab_port) {
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: min-content;
        padding: 0;
        swd-pin-field {
            justify-content: center !important;
        }
    }
  
     @include responsive(phone) {
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: min-content;
        padding: 0;
        
        swd-pin-field {
            justify-content: center !important;
            gap: 8px;
            .pin-field, .pinCodeModal{
                height: 50px;
                width: 50px;
            }
        }
    }

    .activateCodeImg{
        position: relative;
        margin: auto;
        width: 100px;
        left: 0;
        right: 0;
    }

}

.buttonOptionsContainer {
    display: flex;
    gap: 1rem;
     
    button {
        margin-bottom: 1em;
        background-color: $color-primary !important;
        text-transform: uppercase;
        font-size: 1em !important;
        border-radius: $border-radius-button;
        position: relative;
        left: auto;
        right: auto;
        margin: auto;
        width: 140px;
        height: 45px;
    }

    .activate_button, .backToSite_button {
        font-family: $fontClient_Bold !important;
        background-color: $color-primary !important;
        border-color: $color-primary !important;
        color: $color-text-button !important;
     }
    
    .cancel_button {
        background-color: $color-dark !important;
        border: 1px solid #FFFFFF !important;
        font-family: $fontClient_Regular !important;

        &:hover {
            border: 1px solid #FFFFFF !important;
        }
    }
     
 }

 .disabledForm {
    pointer-events: none;
    opacity: 0.4 !important;
 }

 .activateCodeTitle {
    font-family: $fontClient_Bold;
    text-align: center;
    font-size: 2rem;
    margin: 0 16px;
    max-width: 500px;
 }

 .activateCodeSubtitle{
    font-family: $fontClient_Regular;
    text-align: center;
    font-size: 1.4rem;
    color: $color-white !important;
    margin: 0 16px;
    max-width: 500px;
 }

 .activate_button:disabled {
    cursor: not-allowed;
 }


 
 