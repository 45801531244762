@import "../../static/sass/config";

@keyframes zoomToast {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    70% {
        opacity: 0.7;
        transform: scale(1.03);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
 }

.tbxToastContainer{
    z-index: 9999999999999;
    .tbxToastComponent {
        border-radius: 8px;
        background-color:$color-dark; 
        border: 1px solid #707070;
        color: #FFFFFF;
        margin: 16px 24px;
        box-shadow: 0px 8px 16px #00000080;
        transform: scale(1);
        transition: transform 200ms ease-in-out;
        min-width: 17.5em;

        animation: zoomToast 400ms ease-in-out;

        &:hover{
            transform: scale(1.02);
        }
    

        .tbxToastHeader{
            background-color: transparent;
            border-bottom: 0;
        }

        .tbxToastBody{
            display: grid;
            gap: 4px;

            .tbxToastCode{
                color: rgba(255, 255, 255, 0.541);
            }
        }
        .tbxToastTitle{
            text-transform: uppercase;
            color: $color-primary;
        }

        .close{
            color: white; 
            margin-bottom: 8px !important;
            span{
                font-size: 32px;  
                font-weight: 100;
            }
        }
        .bi{
            margin: 4px 16px;
            margin-left: 0;
            &::before{
                font-size: 20px;
            }
            &.bi-info-circle-fill::before{
                color:$color-primary;
            }
            &.bi-exclamation-triangle-fill::before{
                color:$color-primary;
            }
        }
        
    }



    
}