@import "../../static/sass/config";

.navigation.subSection-false~.tbxAppRoutes {
    .hstack-container:first-child .epgComponent:first-child {
        margin-top: $navbar-height !important;

    }
}

.navigation.subSection-true~.tbxAppRoutes {
    .hstack-container:first-child .epgComponent:first-child {
        margin-top: 0;
    }
}



.epgComponent {
    height: 690px;
    padding-top: 40px;
    margin: 0 58px;

    .epgGridList{

        .epgGridList_row_card .epgGridList_row_header button,.epgGridList_container_channel .epgGridList_row_channel_header{
            height: 150px;
        }
        .epgGridList_row_collapse .epgGridList_row_collapse_body,.epgGridList_container_channel .epgGridList_row_channel_body{
            height: 190px;
        }

        .epgGridList_swiper_lineTime{
            width: 5px !important;
            &::before{
                width: 15px !important;
                height: 15px !important;
            }
        }

        .epgGridList_row_itemBody .epgGridList_row_itemBody_container .epgGridList_row_itemBody_text .epgGridList_row_itemBody_description{
            display: -webkit-box;
            white-space: initial;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            height: fit-content;
        }
        .epgGridList_row_card .epgGridList_row_header button.epgGridList_row_header_toggle_selected_true{
            border-width: 4px;
        }

        .epgGridList_row_header_toggle_currentTime_true{
            background-color: $bg-epg-toggle-current-color;
        }

        .programItemHeaderComponentTwo{
            .programItemHeaderComponentTwo_title{
                font-family: $fontClient_Bold;
            }
            .programItemHeaderComponentTwo_subTitle,.programItemHeaderComponentTwo_time{
                font-family: $fontClient_Regular;
            }
            .programItemHeaderComponentTwo_subTitle{
                opacity: 0.8;
            }
            .programItemHeaderComponentTwo_time{
                opacity: 0.6;
            }
        }
        .epgGridList_row_itemBody_description{
            font-family: $fontClient_Regular;
            font-weight: 500;
            opacity: 0.9;
            max-width: 1000px;
        }

        .epgGridList_container_channel .epgGridList_row_channel_header .epgGridList_row_channel_header_container .epgGridList_row_channel_container_img .epgGridList_row_channel_img{
            height: auto;
            width: 100%;
        }
        .epgGridList_swiper .epgGridList_swiper_list .epgGridList_swiper_container .epgGridList_swiper_lineTime::before{
            background-color: $color-primary-dark;
        }

    }
}