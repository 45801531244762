/*OVERRIDE CONTENT OVERVIEWLIVE DEFAULT STYLES*/

.ContentOverviewLiveSection {
  .contentOverviewLiveBackground::before {
    background: linear-gradient(
      to bottom,
      transparent,
      rgba(26, 20, 20, 0.75) 65%,
      $background-color 85%
    );
  }

  .contentOverviewHeader {
    .contentOverviewExitButton {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .contentOverviewInfo {
    .contentOverviewPeriod {
      .contentOverviewCategory {
        .contentOverviewRating {
          padding: 4px 10px;
        }
      }
    }
  }

  .contentOverviewCountdown {
    .countdownTitle {
      font-weight: normal;
    }

    .countdownBox {
      .countdownNumber,
      .countdownLabel {
        font-weight: normal;
      }
    }
  }
}
