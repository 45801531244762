@import "../../../static/sass/config";

.profileForm__container {
   display: grid;
   justify-content: center;
   max-width: 35em;

   .form-group {
      margin-bottom: 0rem !important;
   }

   .disabledRow {
      opacity: 0.4;
      pointer-events: none; 
      
      .bi-chevron-down::before {
         display: none !important;
      }
   }

   .formProfileNameRow {
      &__input {
         border: 0;
         font-family: $fontClient_Regular;
         background-color: rgba(0, 0, 0, 0);
         border-bottom: 1px solid #ffffff;
         color: #ffffff;
         text-align: center;
         width: 100%;
         padding: 9px;
         font-size: 1.25rem;
         outline: transparent;

         &::placeholder {
            color: #989898;
         }
      }
   }

   &_title {
      text-align: left;
      font-size: 2.625em;
   }

   &_kid_profile {
      font-weight: normal;
      text-align: left;
      font-size: 1.5em;
      color: #ffffff;
   }

   &_kid_profile_detail {
      text-align: left;
      font-size: 1em;
      opacity: 1;
      color: #ffffff8a;
      opacity: 1;
   }

   &_adult_content {
      font-weight: normal;
      text-align: left;
      font-size: 1.5em;
      color: #ffffff;
   }

   &_adult_content_detail {
      text-align: left;
      font-size: 1em;
      opacity: 1;
      color: #ffffff8a;
      opacity: 1;
   }

   &_toggle {
      float: right;
      margin-top: 2em;
   }

   &_create_button {
      width: 100%;
      margin-bottom: 1em;
      background-color:$color-primary !important;
      border-color: $color-primary !important;
      font-family: $fontClient_Bold !important;
      text-transform: uppercase;
      color: $color-text-button !important;
      border-radius: $border-radius-button;
      transition: 200ms ease-in-out;
      &:hover{
         color: $color-text-button-hover !important;
         background-color: $color-hover !important;
      }
   }

   &_cancel_button {
      width: 100%;
      margin-bottom: 1em;
      background-color: $color-background !important;
      text-transform: uppercase;
      border: 1px solid #FFFFFF;
      border-radius: $border-radius-button;
      opacity: 1;
   }

   &_cancel_button,
   &_create_button {
      transition: transform ease-in-out 250ms !important;
      transform: scale(1);
      font-family: $fontClient_Regular;
      font-size: 1.25rem;
      &:hover {
         transform: scale(1.02);
      }
   }

   &_cancel_button {
      background-color: $color-secondary !important;
   }

   &_admin_profile {
      font-family: $fontClient_Regular;
      text-align: center;
      color: #ffffff;
      font-size: 1rem;
   }

   .dropDownProfile {
      width: 100%;
      margin-left: 1rem;
      margin-right: 1rem;
      font-family: $fontClient_Regular;
      font-size: 1.5625rem;

      &.show {
         .btn {
            border-bottom: 0px;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
         }
         &::after {
            content: "";
            position: absolute;
            bottom: -4px;
            width: 100%;
            height: 4px;
            left: 0;
            border-left: 1px solid #ffffff;
            border-right: 1px solid #ffffff;
            background-color: #000;
         }
      }
      .dropdown-menu {
         background-color: #000;
         border: 1px solid $color-white;
         border-top: 0px;
         padding-top: 0px;
         border-top-left-radius: 0px;
         border-top-right-radius: 0px;

         .dropdown-item {
            color: #888;

            &:hover {
               text-transform: capitalize;
               color: $color-white;
               background-color: $color-primary-dark;
            }
         }
      }

      .dropDownToggleProfile {
         label {
            float: left;
            margin: 0.2rem;
            font-weight: normal;
         }
      }

      button {
         background-color: transparent;
         border-color: $color-white;
      }

      div {
         width: 100%;
      }

      .bi-chevron-down::before {
         float: right;
         margin-top: 0.5rem;

      }

      .dropdown-toggle::after {
         float: right;
         margin-top: 0.9rem;
         font-size: 1.4rem;
         display: none !important;
      }

      .dropdown-item.hover {
         color: $color-primary-dark;
         background-color: #303030;
      }

      .dropdown-item.active {
         color: white;
         background-color: #303030;
      }
   }
}

.custom-control-input {
   &:checked ~ .custom-control-label::before {
      background-color: $color-primary !important;
      border-color: $color-primary !important;
   }
}

input[placeholder],
[placeholder],
*[placeholder] {
   font-size: 1.5em;
}

.pinCodeDelete {
   float: right;
   cursor: pointer;
}
