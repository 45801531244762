@import "../config";

.tbxBanner{
    margin-bottom: -14%;
    height: auto;
    display: block;
    font-family: $fontClient_Bold;

    .carousel-indicators {
        justify-content: flex-end;
        left: inherit !important;
        padding-left: 0;
        margin-right: 0;
        margin-left: 0;

        @include responsive(tab_medium){
            display: none;
        }

    }

    & .item .bannerHeroImage::before{
        background-image: linear-gradient(to bottom, rgba($background-color, 0.5), rgba($background-color, 0.1), rgba($background-color, 0),rgba($background-color, 0),rgba($background-color, 0), rgba($background-color, 0.1),rgba($background-color, 0.3), rgba($background-color, 0.5), rgba($background-color, 0.7), rgba($background-color, 1))
    }

    .carousel-control-prev, .carousel-control-next {
        width: auto;
    }

    .item{
        @include aspectRatio(16/9);
        height: auto !important;
        .bannerHero_muted{
            bottom: 35%;
        }
        .bannerHero_trailerVideo{
            top: -13%;
            &::before{
                background-image: linear-gradient(to bottom, rgba($background-color, 0.5), rgba($background-color, 0.1), rgba($background-color, 0),rgba($background-color, 0),rgba($background-color, 0.3), rgba($background-color, 0.5),rgba($background-color, 0.6), rgba($background-color, 0.8), rgba($background-color, 1), rgba($background-color, 1))
            }
        }
    }
    .bannerHeroPagination{
        bottom: 24%;
        margin-bottom: 32px;
    }

    .item .carousel-caption {
        margin: 0px 0px $base-size-4x $base-size-6x;
        z-index: 98;
        bottom: 24%;

        @include responsive(tab_medium){
            margin: $base-size-2x;
            width: 92%;
        }

        .bannerHeroInfo{
            margin-right: 360px;

            @include responsive(tab_medium){
                margin-right: 0;
            }

        }

        .bannerHeroTitle h2{

            @include responsive(tab_medium){
                font-size: $text-size-xxl !important;
                margin-top: $base-size;
            }

        }

        .bannerHeroData .networkBadge {
            display: flex;
            color: $color-dark;

            @include responsive(tab_medium){
                font-size: $text-size-xs !important;
                text-transform: uppercase;
                padding: $base-size-half;
            }

        }

        .bannerHeroActions{

            Button.play span{
                color: $color-text-button !important;
            }
            
            @include responsive(tab_medium){
                margin-top: $base-size;
            }

        }

        .bannerHeroActions Button{
            i{
                display: none;
            }
            &:focus {
                outline: none !important; 
                box-shadow: none !important;
            }
            
            @include responsive(tab_medium){
                padding: $base-size;
            }

            &:hover {
                background-color: $color-primary !important;
                border-color: $color-primary !important;
            }

        }

        .bannerHeroActions Button.play span{
            
            @include responsive(tab_medium){
                font-size: $text-size-s !important;
            }

        }
        
        .bannerHeroActions Button.play:hover {
            border-color: $color-primary-dark;
        }

    }

}

