@import "../config";
/*
* CLIENT CUSTOMIZATIONS TBX-WIDGETS
*/

.tbxContentCard {

  &__content-image{
    border-radius: 1rem;
  }
}

.tbxContentListCarousel {
  grid-column: 1 / 13;
  padding-left: 1rem;
  font-family: $fontClient_Bold;
  .tbxContentCard {
    .trailerOverview{
      padding-left: 0;
      padding-bottom: 0;
    }
    &.__imageMode-simple_large{
      .trailerOverview{
        padding-right: 32px;
      }
    }
  }
}

.tbxContentOverview {
  &__title {
    color: $tbx-widget-title-color
  }

  &__rating {
    color: $color-text-tint
  }
}

.tbxContentGrid {
  &__heading {
    font-size: 1.50em;
    font-weight: 500;
    text-transform: uppercase;

    &::after {
      background-color: $color-text-tint;
      content: "";
      display: block;
      margin-top: 1rem;
      height: 3px;
      width: 2em;
    }
  }
}

/* LOADING SPINNER */
.tbx-LoadingSpinner {
  box-shadow: 0px 0px 5px 2px rgba($color-primary, 0.7), 4px 1px 0px $color-primary !important;
}

/* FORGOT MODAL */
.forgotModal {
  &__header {
    .modal-title {
      line-height: 1.2em;

      @include responsive(lg_desktop) {
        max-width: 67%;
      }

      @include responsive(phone) {
        max-width: 100%;
      }
    }
  }

  &__buttons {
    &.modal-footer {
      justify-content: center;
      padding: 1.5rem 0 .5rem 0;
    }
  }
}

// CONTENT OVERVIEW LIVE
.ContentOverviewLiveSection {
  .contentOverviewBackground {
    &::before {
      background: linear-gradient(to bottom, transparent, rgba(26, 20, 20, 0.75) 45%, rgba(0 ,0, 0, 85%)) !important;
    }
  }
}

// ACTIVE DEVICE
.pinCodeInputContainer {
  @include responsive(phone) {
    gap: 10px
  }
}

.pin-field {
  @include responsive(phone) {
    height: 50px;
    width: 50px; 
  }
}
