@import "../../../static/sass/config";

.playerSectionContainer {
    
    padding-top: 150px;
    background-color: $color-background-modal;
    min-height: 100vh;
    .playerContainerVideojs{
        display: flex;
        .playerExitButtomVideojs{
            outline: transparent;
            i{
                font-size: 4.5em;
            }
        } 
    }
    &.playerSectionContainer_loading{
        padding-top: 0px;
    }

}

.playerSectionContainer.fade-exit.fade-exit-active,.playerSectionContainer.fade-exit{
    display: none;
}