@import "../config";

.tbxContentGrid {
  .tbxContentGrid__gallery_wall_filters {
    padding-right: 7rem !important;
    padding-left: 7rem !important;

    .tbxContentGrid__heading_container {
      @include responsive(tab_medium) {
        display: grid;
        justify-content: space-around;
        justify-items: center;
      }

      @include responsive(phone) {
        display: grid;
        justify-content: space-around;
        justify-items: center;
      }
    }

    .tbxContentGrid__heading::after {
      background-color: transparent !important;
    }
  }
}

.tbxContentGridFilter {
  text-transform: uppercase;
  padding-top: 2.5rem;
  color: $color-white;
  font-family: $fontClient_Bold;
  cursor: pointer;

  &__selectedFilter {
    color: $color-primary;
  }
}

.noItemsDescription {
  color: white;
  margin-top: 5rem;
  text-align: center;
  font-size: 2rem;
}
