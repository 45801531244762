@import "../../static/sass/config";

.tbxHandleError {
    display: flex;
    position: fixed;
    inset: 0;
    justify-content: center;
    align-items: center;
    z-index: 99;
    color: white;
    background:  $background-color;

    .tbxHandleError-details[open]{
        .tbxHandleError-summary{
            color: $color-primary;
            font-weight: 500;
            margin-bottom: 16px;
        }
        .tbxHandleError-errorText{
            overflow-y: scroll;
            max-height: 200px;
            margin-left: 16px;
        }
    }

    .tbxHandleError-container{
        display: inline-flex;
        flex-wrap: wrap;
        gap: 24px;
        text-align: center;
        flex-direction: column;
        align-items: center;
    }

    .tbxHandleError-subTitle{
        font-weight: 300; 
        max-width: 500px;
        margin-right: 16px;
        margin-left: 16px;
    }

    .tbxHandleError-logo{
        height: 120px;
        margin-bottom: 1rem;
        width: auto;
    }

    .tbxHandleError-button{
        font-weight: 500;
        text-transform: uppercase;
        font-size: 1rem;
        color: $color-text-button;
        background-color: $color-primary;
        border: 1px solid transparent;
        border-radius: 4px;
        padding: 8px 160px;
    }

}
