@import "../../../../static/sass/config";

.playerEpgSectionPlayer_contentNotAvailable{
    display: flex;
    gap: 2em;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .playerEpgSectionPlayer_contentNotAvailable_body{
        display: flex;
        gap: 2em;
        flex-direction: column;
        align-items: center;
    
        .playerEpgSectionPlayer_contentNotAvailable_body_button{
            font-weight: 500;
            text-transform: uppercase;
            font-size: 1rem;
            color: $color-text-button;
            background-color: $color-primary;
            border: 1px solid transparent;
            border-radius: 4px;
            padding: 8px 160px;
            text-decoration: none;
        }
    }

}