@import "../../../static/sass/config";

.profilePinCode {

    &__container {
        display: grid;
        justify-content: center;
        max-width: 35em;
    }

    &__title {
        font-size: 2.5rem;
        font-family: $fontClient_Regular;
        justify-content: center;
        margin-bottom: 0.625rem;
    }

    &__subTitle {
        font-size: 1.25rem;
        font-family: $fontClient_Regular;
        justify-content: center;
        margin-bottom: 2.188rem;
    }

    &__inputs {
        margin-bottom: 1.438rem;
        margin-bottom: 0.75rem !important;
    }

    &__errorMessage {
        font-size: 1rem;
        font-family: $fontClient_Regular;
        justify-content: center;
    }

    &__forgotPin {
        font-size: 1.25rem;
        font-family: $fontClient_Regular;
        justify-content: center;
        
        label {
            cursor: pointer;
        }
      
    }

    &__enter_button {
        width: 100%;
        background-color: $color-primary !important;
        font-family: $fontClient_Bold !important;
        border-color: $color-primary !important;
        color: $color-text-button !important;
        text-transform: uppercase;
        border-radius: $border-radius-button;
        opacity: 1 !important;
        font-size: 1.25rem !important;
        cursor: pointer !important;

    }

    &__cancel_button {
        width: 100%;
        background-color: $color-dark !important;
        font-family: $fontClient_Regular !important;
        border: 1px solid #FFFFFF !important;
        text-transform: uppercase;
        border-radius: $border-radius-button;
        font-size: 1.25rem !important;
    }
  
}

.forgotModal {

    &__content {
        background-color: #000 !important;
        border: 2px solid #00AEED !important;
        border-radius: 10px !important;
        opacity: 1 !important;
        margin-top: 10rem;
        text-align: center;
        padding: 1rem;
    }

    &__header {
        justify-content: center !important;
        border-bottom: none !important;
        color: #FFFFFF;
        font-family: $fontClient_Regular;
        font-size: 2.063rem;
    }

    &__buttons {
       
        button {
            text-transform: uppercase;
            font-size: 1em !important;
            border-radius: $border-radius-button;
        }

        &__primary {
           background-color: $color-primary !important;
           font-family: $fontClient_Bold !important;
           border-color: $color-primary !important;
           color: $color-text-button !important;
        }

        &__secondary {
           background-color: $color-dark !important;
           font-family: $fontClient_Regular !important;
           border: 1px solid #FFFFFF !important;
        }
       
    }

}

